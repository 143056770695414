import styled from "styled-components";
import { useState } from "react";
import Card from "components/Generic/Card";
import PrimaryButton from "components/Generic/PrimaryButton";
import Carousel from "react-elastic-carousel";
import CardImg from "assets/Product/DefaultImg.png";
import { devices } from "helpers/devices";
import { CardContent } from "interfaces/Content";
import { useDispatch } from "react-redux";
import {
  clearGlobalMessage,
  setGlobalMessage,
  toggleGlobalLoading,
} from "redux/actions";
import { AxiosError, AxiosResponse } from "axios";
import { axiosWebsite } from "helpers/VariableAmbient";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const Section = styled.section``;

const FlexTemplate = styled.div`
  padding: 0 ${props => props.theme.margin_from_sides};
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    padding: 1rem 0.5rem;
  }

  @media ${devices.tabletPortrait} {
    padding: 1rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.tabletLandscape} {
    padding: 1rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.desktop} {
    padding: 0 calc(${props => props.theme.margin_from_sides} + 4rem);
  }

  @media ${devices.bigDesktop} {
    padding: 0 ${props => props.theme.margin_from_sides};
  }
`;

const Title = styled.h4`
  color: ${props => props.theme.color_text1};
  font-size: ${props => props.theme.font_huge};
  text-align: center;
`;

const TitleContainer = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
`;

const CardList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 3rem;
`;

const CardContainer = styled(Card)`
  margin: 0.9rem;
  width: 300px;
  height: 380px;

  @media ${devices.phoneOnly} {
    width: 270px;
  }
`;

const CardTitle = styled.h5`
  font-size: ${props => props.theme.font_medium};
  color: ${props => props.theme.gray2};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 30ch;

  @media ${devices.phoneOnly} {
    white-space: pre-wrap;
    max-height: 40px;
  }
`;

const CardText = styled.p`
  color: ${props => props.theme.gray2};
  font-size: 0.8rem;
  max-width: 30ch;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const DivCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;

  & a {
    text-decoration: none;
  }
`;

const ImgCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const BaseImg = styled.img`
  object-fit: fill;
  max-width: auto;
  max-height: 100%;
`;

const InsertImg = styled.div`
  width: 100%;
  height: 200px;
  margin-bottom: 1rem;
  border-radius: 40px 40px 0 0;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.phoneOnly} {
    width: 100%;
    height: 200px;
  }

  @media ${devices.tabletPortrait} {
    width: 100%;
    height: 200px;
  }
`;

const Button = styled(PrimaryButton)`
  font-size: 1rem;

  @media ${devices.phoneOnly} {
    padding: 0.7rem 1.6rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    padding: 0.7rem 1.6rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletLandscape} {
    padding: 0.7rem 1.6rem;
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    padding: 0.8rem 1.8rem;
    font-size: 0.9rem;
  }

  @media ${devices.bigDesktop} {
    padding: 1rem 2rem;
    font-size: 0.9rem;
  }
`;

const Blog = (): JSX.Element => {
  const [content, setContent] = useState<CardContent[]>([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchContent = () => {
    dispatch(toggleGlobalLoading());

    axiosWebsite
      .get(`/content-site`)
      .then((response: AxiosResponse) => {
        setContent(response.data.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
              buttons: (
                <PrimaryButton
                  onClick={() => {
                    history.push("/");
                    dispatch(clearGlobalMessage());
                  }}
                >
                  Ok
                </PrimaryButton>
              ),
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
            })
          );
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading());
      });
  };

  useEffect(() => {
    fetchContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Section className="Blog" id="5">
      <FlexTemplate>
        <TitleContainer>
          <Title>Notícias</Title>
        </TitleContainer>
        <CardList>
          <Carousel
            isRTL={false}
            itemsToShow={4}
            breakPoints={[
              {
                width: 699,
                itemsToShow: 1,
              },
              {
                width: 700,
                itemsToShow: 2,
              },
              {
                width: 1000,
                itemsToShow: 3,
              },
              {
                width: 1300,
                itemsToShow: 4,
              },
            ]}
          >
            {content.map(content => (
              <CardContainer key={content.id}>
                <ImgCardContainer>
                  <InsertImg>
                    <BaseImg
                      src={
                        content.image_card === "" ||
                        content.image_card === null ||
                        content.image_card === undefined
                          ? CardImg
                          : "data:image/jpeg;base64," + content.image_card
                      }
                      alt="IMG Teste"
                      draggable={false}
                    />
                  </InsertImg>
                  <DivCard>
                    <CardTitle>{content.title}</CardTitle>
                    <CardText>{content.subtitle}</CardText>
                  </DivCard>
                  <DivCard>
                    <a
                      href={
                        content.type === "EXTERNAL"
                          ? content.url
                          : `/trbnews/${content.id}/${content.created_date
                              .substr(0, 10)
                              .split("-")
                              .join("/")}/${content.title.split(" ").join("-")}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button isDisabled={false}>Ler Mais</Button>
                    </a>
                  </DivCard>
                </ImgCardContainer>
              </CardContainer>
            ))}
          </Carousel>
        </CardList>
      </FlexTemplate>
    </Section>
  );
};

export default Blog;
