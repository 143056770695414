import { Button } from "react-bootstrap";
import styled from "styled-components";
import { ReactComponent as IconCart } from "assets/IconCart.svg";

export const Catalog = {
  Button: styled(Button)`
    background-color: #004b87;
    border-radius: 20px;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 0.41em;
    }
  `,

  Button2: styled(Button)`
  background-color: #004b87;
  border-radius: 10px;
  z-index: 20;
  position: absolute;
  font-weight: 500;
  text-transform: uppercase;
  width: 160px;
  padding: 0.5rem;
  top: 15px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.41em;
  }
`,
  Button3: styled(Button)`
background-color: #004b87;
border-radius: 10px;
z-index: 20;
position: relative;
font-weight: 500;
text-transform: uppercase;
width: 160px;
padding: 0.5rem;
margin: 5px;
display: flex;
justify-content: center;
align-items: center;

svg {
  margin-right: 0.41em;
}
`,

  IconCart: styled(IconCart)`
    width: 25px;
    height: 22px;
    overflow: hidden;
    color: #fff;
    fill: #fff;
    font-weight: 700;
  `,
};
