import React from "react";
import { Segments } from "interfaces/Segment";
import { ContainerCatalog } from "components/Catalog";
import Footer from "components/Home/Footer/Footer";
import { useHeaderVisible, useMenuVisible } from "../hooks";
import Header from "components/Home/Header/Header";
import { Logo } from "components/Logo";
import { ButtonMenu } from "components/ButtonMenu";

interface CatalogProps {
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

const Catalog = ({
  fetchSegment,
  segments,
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
}: CatalogProps) => {
  const { isHeaderVisible, handleSetHeader } = useHeaderVisible();
  const { isMenuVisible, handleSetMenu } = useMenuVisible();

  return (
    <>
      <Logo />
      <ButtonMenu isHeaderVisible={isHeaderVisible} onClick={handleSetHeader} />
      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />
      <ContainerCatalog />
      <Footer />
    </>
  );
};

export default Catalog;
