import { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import Home from "pages/Home";
import Product from "pages/Product";
import Catalog from "pages/Catalog";
import SuccessMessage from "components/Generic/SuccessMessage";
import GlobalMessage from "components/Generic/GlobalMessage";
import GlobalLoading from "components/Generic/GlobalLoading";
import { Segments } from "interfaces/Segment";
import { AxiosResponse, AxiosError } from "axios";
import {
  clearGlobalMessage,
  setGlobalMessage,
  toggleGlobalLoading,
} from "redux/actions";
import { axiosWebsite } from "helpers/VariableAmbient";
import PrimaryButton from "components/Generic/PrimaryButton";
import { useDispatch } from "react-redux";
import Content from "pages/Content";
import Pharmacovigilance from "pages/Pharmacovigilance";
import InitialMessageModal from "components/Modals/InitialMessageModal";
import ShowProductPage from "pages/ShowProductPage";
// import WatsonIframe from "components/Iframe/WatsonIframe";

const App = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [segments, setSegments] = useState<Segments[]>([]);
  const [selectSegment, setSelectSegment] = useState<string>("");
  const [segmentName, setSegmentName] = useState<string>("");

  const fetchSegment = () => {
    dispatch(toggleGlobalLoading());

    axiosWebsite
      .get("/segment-site")
      .then((response: AxiosResponse) => {
        setSegments(response.data.data);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
              buttons: (
                <PrimaryButton
                  onClick={() => {
                    history.push("/");
                    dispatch(clearGlobalMessage());
                  }}
                >
                  Ok
                </PrimaryButton>
              ),
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
            })
          );
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading());
      });
  };

  return (
    <>
      <SuccessMessage />
      <GlobalMessage />
      <GlobalLoading />
      {/* <WatsonIframe /> */}

      <Router>
        <Switch>
          <Route path="/pharmacovigilance">
            <Pharmacovigilance
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>
          <Route path="/trbnews/:id/:created_date/:title">
            <Content
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>
          <Route path="/product/:id/:name">
            <Product
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>

          <Route path="/comprar" exact>
            <Catalog
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>
          <Route path="/comprar/:type" exact>
            <Catalog
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>

          <Route path="/comprar/:id_segment/:eams" exact>
            <ShowProductPage
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>

          <Route path="/">
            <InitialMessageModal />
            <Home
              segments={segments}
              segmentId={selectSegment}
              segmentName={segmentName}
              selectedSegmentName={value => setSegmentName(value)}
              selectedSegment={value => setSelectSegment(value)}
              fetchSegment={fetchSegment}
            />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
