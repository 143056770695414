import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import styled from 'styled-components'
import { api } from 'services/api'
import { MessageModel } from 'contracts/app'
import AvatarThalita from 'assets/avatar.png'

const ModalContainer = styled.div`
  .modal-container {
    padding: 0;
  }

  .modal-dialog {
    margin: 0;
  }
`

const InitialMessageModal: React.FC = () => {
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState<MessageModel>()

  useEffect(() => {
    const lastContent = localStorage.getItem('lastContent')

    api.getInitialMessage().then(message => {
      const openMessage = () => {
        if (message?.body) {
          localStorage.setItem('lastContent', message.body)
          setMessage(() => message)
          setShow(() => true)
        }
      }
      openMessage()

      // if (lastContent) {
      //   if (message && lastContent === message.body) {
      //     setShow(() => false);
      //   } else {
      //     openMessage();
      //   }
      // } else {
      //   if (message && message.body) {
      //     openMessage();
      //   }
      // }
    })
  }, [])

  if (!show || !message) return <></>

  return (
    <ModalContainer>
      <Modal
        size={'lg'}
        backdrop='static'
        show={show}
        style={{ background: 'transparent' }}
      >
        <Modal.Dialog
          as='div'
          style={{
            width: '100%',
            maxWidth: '100%',
            height: '100%',
            margin: 0
          }}
        >
          <Modal.Header
            style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}
          >
            <Modal.Title
              as='p'
              style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}
              dangerouslySetInnerHTML={{ __html: message.title! }}
            ></Modal.Title>
          </Modal.Header>

          <Modal.Body
            style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}
          >
            <div
              style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
            >
              <div
                style={{ flex: 1, lineHeight: '120%' }}
                dangerouslySetInnerHTML={{ __html: message.body! }}
              ></div>
              <div
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  padding: '0 20px',
                  display: 'flex'
                }}
              >
                <img
                  src={AvatarThalita}
                  aria-label='Thalita'
                  style={{ objectFit: 'cover' }}
                />
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer
            style={{ border: 'none', paddingTop: 0, paddingBottom: 0 }}
          >
            <Button variant='' onClick={() => setShow(false)}>
              {message.footer_button}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </ModalContainer>
  )
}

export default InitialMessageModal
