import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchProducts } from "../service";
import { GlobalState } from "redux/types";

import { Product } from "./Product.styles";

import type { ProductProps, ProductResponse } from "./Product.d";
import { DivButtons } from "../../Product/ProductFolder/components";
import { Locatestore } from "../components";
import { toggleCatalog, toggleGlobalLoading } from "redux/actions";
import { CardProduct } from "interfaces/Product";
import { useCatalogQuery } from "hooks/useCatalogQuery";

const ContainerProduct = ({ segment, onClick }: ProductProps) => {
  const { ean, segmentId } = segment;
  // const catalogList = useSelector((state: GlobalState) => state.catalogList);
  const {
    catalog,
    isLoading: isLoadingCatalog,
    reload: reloadCatalog,
  } = useCatalogQuery();
  const [resumo, setResumo] = useState<ProductResponse["details"]>("");
  const dispatch = useDispatch();
  const history = useHistory();

  const [data, setData] = useState([] as CardProduct[]);

  const fetchMyAPI = useCallback(async () => {
    if (segmentId && catalog.length > 0) {
      dispatch(toggleGlobalLoading());

      let response = await fetchProducts(segmentId);

      console.log("RESPONSE", response);

      const product = response?.find((item: any) => {
        return item.eam.join() === ean.join();
      });

      setData([product]);

      // console.log({ product });

      const productDetail = catalog?.find(item => {
        return item.eam.join() === ean.join();
      });

      console.log({ productDetail, resumo });

      if (!resumo) {
        setResumo(productDetail?.resumo!);
      }

      dispatch(toggleGlobalLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentId, catalog.length]);

  useEffect(() => {
    if (catalog.length <= 0) {
      reloadCatalog().then(() => {
        fetchMyAPI();
      });
    } else {
      fetchMyAPI();
    }

    return () => {
      dispatch(toggleCatalog(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchMyAPI, catalog]);

  if (isLoadingCatalog) return <></>;

  return (
    <>
      <Product.ArrowLeft onClick={onClick}>
        <span>&#8594;</span> Voltar para os produtos
      </Product.ArrowLeft>

      {data.length > 0 ? (
        data?.map(item => {
          return (
            <Product.Container key={`itemList${item?.id}`}>
              <img
                src={`data:image/jpeg;base64,${item?.image}`}
                alt={item?.drugleaflet_label_2}
              />
              <Product.Details>
                <h1
                  dangerouslySetInnerHTML={{
                    __html: item.name?.replace("®", "<sup>®</sup>"),
                  }}
                ></h1>
                {resumo && parse(resumo)}
                {/* {parse(item?.details || item?.resumo)} */}

                <Product.DivButton>
                  <DivButtons
                    dispatch={dispatch}
                    history={history}
                    id={item?.id}
                    drugleaflet_label_1={item?.drugleaflet_label_1}
                    drugleaflet_label_2={item?.drugleaflet_label_2}
                    drugleaflet_label_3={item?.drugleaflet_label_3}
                  />

                  <Locatestore eamProduct={ean} />
                </Product.DivButton>
              </Product.Details>
            </Product.Container>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default ContainerProduct;
