import { toSlug } from "helpers/toSlug";
import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { Catalog } from "../Catalog.styles";

type FilterPros = {
  onClick: (el: string) => void;
  total: number;
  segments?: {
    [k in string]: number;
  };
};

const Filter = ({ onClick, total, segments }: FilterPros) => {
  const handleClick = (event: any) => {
    document.querySelector("ul.filter li.active")?.classList.remove("active");

    const target = event.target as HTMLInputElement;
    onClick(target.getAttribute("data-filter")!);

    event.target.classList.add("active");
  };

  const history = useHistory();
  const { url } = useRouteMatch();
  console.log({ url });

  const registerLink = (to: string) => {
    return {
      onClick: () => {
        history.push(to);
      },
      className: url === to ? "active" : undefined,
    };
  };

  return (
    <Catalog.Filter>
      <strong>Filtrar</strong>

      <ul className="filter">
        <li {...registerLink("/comprar/todos")}>Todos ({total})</li>
        {segments ? (
          Object.entries(segments).map(([item, value]) => {
            return (
              <li
                data-filter={item}
                key={`${item}-${value}`}
                {...registerLink(`/comprar/${toSlug(item)}`)}
              >
                {item} ({value})
              </li>
            );
          }, 0)
        ) : (
          <></>
        )}
      </ul>
    </Catalog.Filter>
  );
};

export default Filter;
