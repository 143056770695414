import styled, { keyframes } from "styled-components";
import { devices } from "helpers/devices";
import PrimaryButton from "components/Generic/PrimaryButton";

const slideBody = keyframes`
  from {
    opacity: 0;
    transform: translateY(30%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const Product = {
  Button: styled(PrimaryButton)`
    margin: 1em 1em 1em 0;
    border-radius: 25px;
    background: ${props => props.theme.color_secondary_dark};

    @media ${devices.phoneOnly} {
      font-size: 0.7rem;
      padding: 1rem;
      min-width: 80px;
    }
  `,

  DivButtons: styled.div<{ delay: string }>`
    display: flex;
    flex-direction: row;
    animation: ${slideBody} 1s 1 both ${props => props.delay};
    & a {
      text-decoration: none;
    }
  `,
};
