import styled from "styled-components";
import { devices } from "helpers/devices";

const variantStyled = {
  color: "#6c757d",
};

export const Catalog = {
  Breadcrumb: styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 1rem;
    padding: 0;

    li {
      float: left;
      padding: 0 0.5rem;

      a {
        color: ${variantStyled.color};
        cursor: pointer;
        text-decoration: none;
      }

      &.active:before {
        content: "";
        border: solid ${variantStyled.color};
        border-width: 0 2px 2px 0;
        display: inline-block;
        float: none;
        padding: 3px;
        position: relative;
        left: -10px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  `,

  BoxSelect: styled.div`
    background: #fff;
    padding: 1em;
    padding-top: 0;

    select {
      padding: 1em;

      &:focus {
        border-color: #ced4da;
        box-shadow: none;
        outline: 0;
      }
    }
  `,

  Small: styled.small`
    color: #a7a8aa;
    display: block;
    font-size: 11px;
    margin: 5px 0 10px 2px;
    text-align: left;
  `,

  TitleModal: styled.h2`
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    background: #fff;
    padding: 1em 0;
    font-weight: 600;
  `,

  Col: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,

  Container: styled.section`
    margin-bottom: 35px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    width: 100%;
    margin-top: 13rem;

    @media ${devices.phoneOnly} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem ${props => props.theme.margin_from_sides_mobile};
    }

    @media ${devices.tabletPortrait} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem ${props => props.theme.margin_from_sides_mobile};
    }

    @media ${devices.tabletLandscape} {
      /* display: grid; */
      padding: 0 ${props => props.theme.margin_from_sides};
    }

    @media ${devices.desktop} {
      padding: 0 calc(${props => props.theme.margin_from_sides} + 4rem);
    }

    @media ${devices.bigDesktop} {
      padding: 0 ${props => props.theme.margin_from_sides};
    }
  `,

  Content: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
  `,

  List: styled.div`
    display: grid;

    grid-column-gap: 4em;
    grid-row-gap: 5em;

    @media ${devices.phoneOnly} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    @media ${devices.tabletPortrait} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @media ${devices.tabletLandscape} {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    @media ${devices.bigDesktop} {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }
  `,

  Detail: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  `,

  Filter: styled.div`
    box-shadow: 0px 0px 4px 0px #ccc;
    border-radius: 2px;
    max-width: 310px;
    min-width: 260px;

    strong {
      display: block;
      font-size: 22px;
      font-weight: 600;
      padding: 10px;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      margin: 10px 0;
      padding: 0;

      li {
        border-left: 5px solid #fff;
        cursor: pointer;
        padding: 10px;

        &.active {
          background: #f2f2f2;
          border-color: #004b87;
          color: #004b87;
          font-weight: 600;
        }
      }
    }
  `,

  Title: styled.strong`
    color: #3b3f42;
    font-size: 1.5rem;
    text-align: center;
    margin: 10px 0;
  `,

  Resumo: styled.div`
    margin-bottom: 1rem;
    min-height: 170px;

    p {
      margin-bottom: 0;
    }
  `,

  Tag: styled.span`
    align-items: center;
    background: #ccc;
    color: #fff;
    font-size: 0.7em;
    font-weight: 600;
    line-height: 12px;
    text-transform: uppercase;
    padding: 6px;

    &[data-variant="primary"] {
      background: #000;
    }

    &[data-variant="secondary"] {
      background: red;
    }

    &[data-variant="darkLight"] {
      background: green;
      color: #fff;
    }
  `,

  Retailer: styled.div`
    > div {
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 10px 15px;
      margin: 1em;
    }

    .image {
      align-items: center;
      display: flex;

      img {
        width: 90px;
      }
    }

    .price {
      align-items: center;
      display: flex;
      font-size: 14px;
      font-weight: 600;
    }

    .status {
      align-items: center;
      color: #026f02;
      display: flex;
      font-size: 14px;
    }

    .btn.col a.btn.btn-primary {
      border-radius: 100px;
      background-color: #004b87;
      border-color: #004b87;
      display: flex;
      font-size: 13px;
      justify-content: space-between;
      width: 98px;
    }
  `,
};
