import { ButtonMenu } from "components/ButtonMenu";
import { ContainerCatalog } from "components/Catalog";
import { Catalog } from "components/Catalog/Catalog.styles";
import { Product } from "components/Catalog/Product";
import Footer from "components/Home/Footer/Footer";
import Header from "components/Home/Header/Header";
import { Logo } from "components/Logo";
import { useHeaderVisible, useMenuVisible } from "hooks";
import { Segments } from "interfaces/Segment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface WithHeaderProps {
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

const ShowProductPage: React.FC<WithHeaderProps> = ({
  fetchSegment,
  segmentId,
  segmentName,
  segments,
  selectedSegment,
  selectedSegmentName,
}) => {
  const params = useParams<any>();
  const history = useHistory();
  const { isHeaderVisible, handleSetHeader } = useHeaderVisible();
  const { isMenuVisible, handleSetMenu } = useMenuVisible();

  useEffect(() => {
    console.log(params);
  }, [params]);

  return (
    <>
      <Logo />
      <ButtonMenu isHeaderVisible={isHeaderVisible} onClick={handleSetHeader} />
      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />
      <Catalog.Container>
        <Product
          segment={{
            segmentId: params.id_segment,
            ean: params.eams.split("__"),
          }}
          onClick={() => {
            history.push(`/comprar`);
          }}
        />
      </Catalog.Container>
      <Footer />
    </>
  );
};

export default ShowProductPage;
