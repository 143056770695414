//imports
import { useState } from 'react'
import styled from 'styled-components'
import Scroll from 'react-scroll'

//import de componentes
import Blog from 'components/Home/Blog/Blog'
import Conheca from 'components/Home/Conheca/Conheca'
import Footer from 'components/Home/Footer/Footer'
import Hero from 'components/Home/Hero/Hero'
import Missao from 'components/Home/Missao/Missao'
import SaberMais from 'components/Home/SaberMais/SaberMais'

import Header from 'components/Home/Header/Header'
import { Segments } from 'interfaces/Segment'
import { useEffect } from 'react'
import { ButtonMenu } from 'components/ButtonMenu'
import { useHeaderVisible } from 'hooks'
import { Catalog } from 'components/Catalog/components/Button/Button.styles'

//instanciando a variavel scroller com a função do componente importado
const scroller = Scroll.scroller

//Criando os Css atraves do styled component
const Container = styled.section`
  position: relative;
`

interface Props {
  segmentId: string
  selectedSegment: (value: string) => void
  selectedSegmentName: (value: string) => void
  fetchSegment: () => void
  segments: Segments[]
  segmentName: string
}

//Criando o elemento a ser compilado
const Home = ({
  segmentId,
  selectedSegment,
  fetchSegment,
  segments,
  selectedSegmentName,
  segmentName
}: Props): JSX.Element => {
  //utilizando useState
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const handleSetMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const { isHeaderVisible, handleSetHeader } = useHeaderVisible()

  const newScroll = async () => {
    const toScroll = localStorage.getItem('scroller')
    if (toScroll) {
      await scroller.scrollTo(toScroll, {
        duration: 1500,
        delay: 100,
        smooth: true,
        offset: -50
      })
    }
    await localStorage.removeItem('scroller')
  }

  useEffect(() => {
    newScroll()
  }, [])

  return (
    <Container>
      <ButtonMenu isHeaderVisible={isHeaderVisible} onClick={handleSetHeader} />

      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />

      <Hero />

      <Conheca />
      <Missao />
      <SaberMais
        handleSetMenu={handleSetMenu}
        handleSetHeader={handleSetHeader}
      />
      <Blog />

      <Footer />
    </Container>
  )
}

export default Home
