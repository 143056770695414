import styled from "styled-components";

const PrimaryButton = styled.button<{
  margins?: string;
  width?: string;
  height?: string;
  isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: ${props => (props.margins ? props.margins : "0")};
  padding: 1rem 2.4rem;
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  color: ${props => props.theme.color_text2};
  background-color: ${props =>
    props.isDisabled ? props.theme.gray4 : props.theme.color_primary_main};
  border: none;
  border-radius: 14px;
  outline: none;
  transition: background-color 400ms ease;
  cursor: pointer;

  &:hover {
    background-color: ${props =>
      props.isDisabled ? props.theme.gray4 : props.theme.color_primary_light};
  }

  & svg {
    margin-left: 0.4rem;
    height: 1rem;
    width: 1.4rem;
  }
`;

export default PrimaryButton;
