import styled from "styled-components";
import BgSaberMais from "assets/BgSaberMais.png";
import PrimaryButton from "components/Generic/PrimaryButton";
import Scroll from "react-scroll";
import { devices } from "helpers/devices";

const scroller = Scroll.scroller;

const Container = styled.section`
  background-image: url("${BgSaberMais}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const FlexTemplate = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    padding: 2rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.tabletPortrait} {
    padding: 2rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.tabletLandscape} {
    padding: 2rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.desktop} {
    padding: 4rem calc(${props => props.theme.margin_from_sides} + 4rem);
  }

  @media ${devices.bigDesktop} {
    padding: 3rem ${props => props.theme.margin_from_sides};
  }
`;

const Text = styled.h2`
  max-width: 50%;
  color: ${props => props.theme.color_text2};
  text-align: center;
  font-weight: bold;

  @media ${devices.phoneOnly} {
    font-size: 1.2rem;
    max-width: 100%;
  }

  @media ${devices.tabletPortrait} {
    max-width: 80%;
  }

  @media ${devices.tabletLandscape} {
    max-width: 70%;
  }

  @media ${devices.desktop} {
    max-width: 50%;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
`;

const Button = styled(PrimaryButton)`
  background-color: ${props => props.theme.color_secondary_main};
  font-size: 1.1rem;

  &:hover {
    background-color: ${props => props.theme.color_secondary_light};
  }

  @media ${devices.phoneOnly} {
    width: 150px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    width: 180px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletLandscape} {
    width: 180px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    width: 200px;
    padding: 0.8rem;
    font-size: 1rem;
  }

  @media ${devices.bigDesktop} {
    width: 220px;
    padding: 1rem;
    font-size: 1.1rem;
  }
`;

interface Props {
  handleSetMenu: () => void;
  handleSetHeader: () => void;
}

const SaberMais = ({ handleSetMenu, handleSetHeader }: Props): JSX.Element => {
  return (
    <Container className="SaberMais" id="4">
      <FlexTemplate>
        <Text>
          Que o futuro seja reflexo do que o presente faz e do que o passado
          ensinou.
        </Text>
        <ButtonContainer>
          <Button
            width="100%"
            onClick={event => {
              scroller.scrollTo("1", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -40,
              });
              handleSetHeader();
              handleSetMenu();
            }}
          >
            Saber Mais
          </Button>
        </ButtonContainer>
      </FlexTemplate>
    </Container>
  );
};

export default SaberMais;
