import styled from "styled-components";

const Card = styled.div<{
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
}>`
  margin: ${props => (props.margin ? props.margin : "0")};
  padding: ${props => (props.padding ? props.padding : ".2rem")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 8px ${props => props.theme.gray4};
  border-radius: 40px;
  background: ${props => props.theme.white1};
  height: ${props => props.height};
  width: ${props => props.width};
`;

export default Card;
