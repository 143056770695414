import styled from "styled-components";
import BgHero from "assets/BgHero.png";
import LogoHeader from "assets/LogoHeader.png";
import PrimaryButton from "components/Generic/PrimaryButton";
import { devices } from "helpers/devices";
//import components to scroller
import Scroll from "react-scroll";

const scroller = Scroll.scroller;

const Container = styled.section`
  background-image: url("${BgHero}");
  background-position: center bottom 10%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${devices.phoneOnly} {
    background-position: center left 0%;
  }

  @media ${devices.tabletPortrait} {
    background-position: center left 0%;
  }

  @media ${devices.tabletLandscape} {
    background-position: center bottom 10%;
  }
`;

const GridTemplate = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 2fr 1fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    padding: 2rem ${props => props.theme.margin_from_sides_mobile};
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${devices.tabletPortrait} {
    padding: 5rem ${props => props.theme.margin_from_sides_mobile};
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${devices.tabletLandscape} {
    padding: 5rem ${props => props.theme.margin_from_sides};
    grid-template-columns: repeat(1, 1fr);
  }

  @media ${devices.desktop} {
    padding: 5rem calc(${props => props.theme.margin_from_sides} + 4rem);
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${devices.bigDesktop} {
    padding: 0 ${props => props.theme.margin_from_sides};
  }
`;

const Logo = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  top: 30px;
  left: 60px;

  @media ${devices.phoneOnly} {
    top: 0px;
    left: 5px;
    width: 100%;
    max-width: 200px;
  }

  @media ${devices.tabletPortrait} {
    top: 10px;
    left: 20px;
    width: 100%;
    max-width: 350px;
  }

  @media ${devices.tabletLandscape} {
    top: 30px;
    left: 50px;
    width: 40%;
    max-width: 350px;
  }

  @media ${devices.desktop} {
    top: 30px;
    left: 60px;
    width: 40%;
    max-width: 450px;
  }

  @media ${devices.bigDesktop} {
    width: 30%;
    max-width: 550px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-start: 1;
  grid-row-start: 2;

  @media ${devices.phoneOnly} {
    align-items: center;
  }

  @media ${devices.tabletPortrait} {
    align-items: center;
  }

  @media ${devices.tabletLandscape} {
    align-items: flex-start;
  }
`;

const Title = styled.h1`
  color: ${props => props.theme.color_text1};
  font-size: ${props => props.theme.font_huge};
  font-weight: bold;

  @media ${devices.phoneOnly} {
    text-align: center;
    font-size: 1.5rem;
    max-width: 400px;
  }

  @media ${devices.tabletPortrait} {
    text-align: center;
    font-size: 1.8rem;
    max-width: 400px;
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    font-size: 1.8rem;
    max-width: 400px;
  }

  @media ${devices.desktop} {
    font-size: 2rem;
    max-width: none;
  }

  @media ${devices.bigDesktop} {
    font-size: 2.5rem;
  }
`;

const Text = styled.p`
  color: ${props => props.theme.color_text1};
  font-size: ${props => props.theme.font_large};

  @media ${devices.phoneOnly} {
    text-align: center;
    font-size: 1.4rem;
    max-width: 300px;
  }

  @media ${devices.tabletPortrait} {
    text-align: center;
    font-size: 1.6rem;
    max-width: 400px;
  }

  @media ${devices.tabletLandscape} {
    text-align: start;
    font-size: 1.6rem;
    max-width: 400px;
  }

  @media ${devices.desktop} {
    font-size: 1.8rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 2.3rem;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 2rem;
  grid-column-start: 1;
  grid-row-start: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Button = styled(PrimaryButton)`
  background-color: ${props => props.theme.color_secondary_dark};
  width: 220px;
  font-size: 1.1rem;

  &:hover {
    background-color: ${props => props.theme.color_secondary_dark2};
  }

  @media ${devices.phoneOnly} {
    align-self: center;
    width: 180px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletPortrait} {
    align-self: center;
    width: 180px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.tabletLandscape} {
    align-self: flex-start;
    width: 180px;
    padding: 0.7rem;
    font-size: 0.9rem;
  }

  @media ${devices.desktop} {
    width: 200px;
    padding: 0.8rem;
    font-size: 1rem;
  }

  @media ${devices.bigDesktop} {
    width: 220px;
    padding: 1rem;
    font-size: 1.1rem;
  }
`;

const Hero = (): JSX.Element => {
  return (
    <Container className="Hero" id="1">
      <Logo src={LogoHeader} alt="Logo da TRB PHARMA" />
      <GridTemplate>
        <TextContainer>
          <Title>
            Medicamentos de ponta com o melhor da tecnologia laboratorial
          </Title>
          <Text>Inovação e tecnologia sem agredir o meio ambiente.</Text>
        </TextContainer>
        <ButtonContainer>
          <Button
            onClick={() =>
              scroller.scrollTo("2", {
                duration: 1500,
                delay: 100,
                smooth: true,
                offset: -40,
              })
            }
          >
            Saber Mais
          </Button>
        </ButtonContainer>
      </GridTemplate>
    </Container>
  );
};

export default Hero;
