import styled from "styled-components";

import { devices } from "helpers/devices";

export const ButtonMenu = styled.button<{ Fill?: boolean }>`
  border: none;
  background: transparent;
  position: fixed;
  z-index: 13;
  top: 0;
  right: 0;
  margin: 0.7rem;
  display: none;

  & svg {
    width: 30px;
    height: 30px;
    fill: ${props =>
      props.Fill
        ? props.theme.color_secondary_main
        : props.theme.color_primary_main};
  }

  @media ${devices.phoneOnly} {
    display: block;
  }

  @media ${devices.tabletPortrait} {
    display: flex;
  }

  @media ${devices.desktop} {
    display: none;
  }
`;
