import styled from "styled-components";

import { devices } from "helpers/devices";

export const Logo = {
  Image: styled.img`
    width: 30%;
    height: auto;
    position: absolute;
    top: 30px;
    left: 60px;

    @media ${devices.phoneOnly} {
      top: 0px;
      left: 5px;
      width: 100%;
      max-width: 200px;
    }

    @media ${devices.tabletPortrait} {
      top: 10px;
      left: 20px;
      width: 100%;
      max-width: 350px;
    }

    @media ${devices.tabletLandscape} {
      top: 30px;
      left: 50px;
      width: 40%;
      max-width: 350px;
    }

    @media ${devices.desktop} {
      top: 30px;
      left: 60px;
      width: 40%;
      max-width: 450px;
    }

    @media ${devices.bigDesktop} {
      width: 30%;
      max-width: 550px;
    }
  `,
};
