import React from 'react'

import type { ButtonProps } from './Button.d'
import { Catalog } from './Button.styles'

const ButtonBase = ({ onClick, isLoading, productCode }: ButtonProps) => {
  const handleClick = () => {
    onClick(productCode)
  }

  return (
    <Catalog.Button
      variant='primary'
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
      size='lg'
    >
      <Catalog.IconCart />

      {isLoading ? 'Carregando...' : 'COMPRE AQUI'}
    </Catalog.Button>
  )
}

export default ButtonBase
