export interface consumptionRoute {
  productName: String;
  consumption: String[];
}

export const consumptionRouteValue = [
  {
    productName: "ARTISAL",
    consumption: [{ name: "Tópico" }],
  },
  {
    productName: "ARTRODAR",
    consumption: [{ name: "Oral" }],
  },
  {
    productName: "HYALUDERMIN",
    consumption: [{ name: "Tópico" }],
  },
  {
    productName: "OSTEONIL",
    consumption: [
      { name: "Intra-articular" },
      { name: "Peritendinosa" },
      { name: "Bainha tendinosa" },
      { name: "Periarticular" },
    ],
  },
  {
    productName: "OSTEONIL MINI",
    consumption: [
      { name: "Intra-articular" },
      { name: "Peritendinosa" },
      { name: "Bainha tendinosa" },
      { name: "Periarticular" },
    ],
  },
  {
    productName: "OSTEONIL PLUS",
    consumption: [
      { name: "Intra-articular" },
      { name: "Peritendinosa" },
      { name: "Bainha tendinosa" },
      { name: "Periarticular" },
    ],
  },
  {
    productName: "POLIREUMIN",
    consumption: [{ name: "Intra-articular" }],
  },
  {
    productName: "SYGEN",
    consumption: [{ name: "Intramuscular" }, { name: "Intravenoso" }],
  },
  {
    productName: "VISCOSEAL",
    consumption: [{ name: "Intra-articular" }],
  },
  {
    productName: "VISIOL",
    consumption: [{ name: "Intraocular" }],
  },
];
