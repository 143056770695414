import { useState } from "react";

export function useMenuVisible() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const handleSetMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return {
    isMenuVisible,
    handleSetMenu,
  };
}
