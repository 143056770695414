import { devices } from "helpers/devices";
import styled from "styled-components";

const GenericButton = styled.button<{
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 0.9rem;
  margin: ${props => (props.margin ? props.margin : ".5rem .2rem")};
  padding: ${props => (props.padding ? props.padding : ".8rem 2rem")};
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "auto")};
  color: ${props =>
    props.isDisabled ? props.theme.color_text2 : props.theme.white1};
  background-color: ${props =>
    props.isDisabled
      ? props.theme.button_deny
      : props.theme.color_secondary_main};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "5px")};
  transition: background-color 400ms ease;
  cursor: pointer;
  border: none;
  outline: none;
  font-weight: bold;

  &:hover {
    background-color: ${props =>
      props.isDisabled
        ? props.theme.button_deny_hover
        : props.theme.color_secondary_light};
  }

  @media ${devices.desktop} {
    font-size: 0.8rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 0.9rem;
  }
`;

export default GenericButton;
