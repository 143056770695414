import styled, { keyframes } from 'styled-components'
import Header from 'components/Home/Header/Header'
import PrimaryButton from 'components/Generic/PrimaryButton'
import Footer from 'components/Home/Footer/Footer'
import { devices } from 'helpers/devices'
//import de imagens
import BgAreas from 'assets/bgareas.png'
import LogoHeader from 'assets/LogoHeader.png'
import NoImg from 'assets/Product/DefaultImg.png'
import { useState } from 'react'
import {
  clearGlobalMessage,
  setGlobalMessage,
  toggleGlobalLoading
} from 'redux/actions'
import { axiosWebsite } from 'helpers/VariableAmbient'
import { AxiosError, AxiosResponse } from 'axios'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { CardProduct } from 'interfaces/Product'
import { Segments } from 'interfaces/Segment'
import parse from 'html-react-parser'
import { PathProductNav } from 'interfaces/PathNav'
import { DivButtons } from './components'
import { Locatestore } from 'components/Catalog/components'

const slideImg = keyframes`
    from {
        opacity: 0;
        transform: translateX(-30%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
`

const slideTitle = keyframes`
    from {
        opacity: 0;
        transform: translateY(-30%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
`

const slideBody = keyframes`
    from {
        opacity: 0;
        transform: translateY(30%);
    }

    to {
        opacity: 1;
        transform: translateY(0%);
    }
`

const Container = styled.section`
  background: url('${BgAreas}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* height: 100vh; */
  position: relative;
`

const BgProduct = styled.div`
  background: rgba(255, 255, 255, 0.8);
  height: auto;
  overflow: auto;
`

const GridTemplate = styled.div`
  padding: 0 ${props => props.theme.margin_from_sides};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    padding: 0 ${props => props.theme.margin_from_sides_mobile};
    grid-template-columns: 1fr;
  }

  @media ${devices.tabletPortrait} {
    padding: 0 ${props => props.theme.margin_from_sides_mobile};
    grid-template-columns: 1fr;
  }

  @media ${devices.tabletLandscape} {
    padding: 0 ${props => props.theme.margin_from_sides};
    grid-template-columns: repeat(4, 1fr);
  }
`

const Logo = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  top: 30px;
  left: 60px;

  @media ${devices.phoneOnly} {
    top: 0px;
    left: 5px;
    width: 100%;
    max-width: 200px;
  }

  @media ${devices.tabletPortrait} {
    top: 10px;
    left: 20px;
    width: 100%;
    max-width: 350px;
  }

  @media ${devices.tabletLandscape} {
    top: 30px;
    left: 50px;
    width: 40%;
    max-width: 350px;
  }

  @media ${devices.desktop} {
    top: 30px;
    left: 60px;
    width: 40%;
    max-width: 450px;
  }

  @media ${devices.bigDesktop} {
    width: 30%;
    max-width: 550px;
  }
`

const Title = styled.h1`
  color: ${props => props.theme.color_text1};
  font-size: 2rem;
  text-align: center;
  margin: 2rem;
  margin-top: 13rem;

  @media ${devices.phoneOnly} {
    font-size: 1.3rem;
  }
`

const ProductsContainer = styled.div`
  grid-column-start: 1;
  grid-row-start: 2;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  min-height: 900px;
  height: 100%;

  @media ${devices.phoneOnly} {
    width: 100%;
  }
`

const Product = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 3rem 0;

  @media ${devices.phoneOnly} {
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tabletPortrait} {
    flex-direction: column;
    align-items: center;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: row;
    align-items: flex-start;
  }
`

const InsertImg = styled.div<{ delay: string }>`
  width: 400px;
  height: 400px;
  overflow: hidden;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${slideImg} 1s 1 both ${props => props.delay};
`

const BaseImg = styled.img`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
`

const ContentText = styled.div`
  width: 100%;
`

const MedicTitle = styled.h1<{ delay: string }>`
  color: ${props => props.theme.color_text3};
  font-size: 2rem;
  margin: 0.5rem;
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
  white-space: pre-wrap;
  max-width: 900px;
  animation: ${slideTitle} 1s 1 both ${props => props.delay};
`

const MedicPrinciple = styled.p<{ delay: string }>`
  color: ${props => props.theme.color_primary_main};
  font-size: 1.3rem;
  margin: 0.5rem;
  align-self: flex-start;
  white-space: pre-wrap;
  animation: ${slideTitle} 1s 1 both ${props => props.delay};
  flex: 1;
`

const MedicText = styled.p<{ delay: string }>`
  margin: 1rem 0.5rem;
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
  white-space: pre-wrap;
  max-width: 900px;
  animation: ${slideBody} 1s 1 both ${props => props.delay};
`

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${devices.desktop} {
    flex-direction: row;
  }
`

const ButtonWrap = styled.div`
  width: 100%;

  @media ${devices.desktop} {
    width: auto;
  }
`

interface Props {
  isHeaderVisible: boolean
  handleSetHeader: () => void
  segmentName: string
  segmentId: string
  selectedSegment: (value: string) => void
  selectedSegmentName: (value: string) => void
  fetchSegment: () => void
  segments: Segments[]
}

const ProductRender = ({
  isHeaderVisible,
  handleSetHeader,
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
  fetchSegment,
  segments
}: Props): JSX.Element => {
  const [products, setProducts] = useState<CardProduct[]>([])
  const dispatch = useDispatch()
  const history = useHistory()
  const pathNav = useParams<PathProductNav>()
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const handleSetMenu = () => {
    setIsMenuVisible(!isMenuVisible)
  }

  const fetchProducts = () => {
    dispatch(toggleGlobalLoading())

    axiosWebsite
      .get(`/product-site?segment_id=${pathNav.id}`)
      .then((response: AxiosResponse) => {
        setProducts(response.data.data)
        history.push(
          `/product/${pathNav.id}/${pathNav.name.split(' ').join('-')}`
        )
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: 'ERROR',
              buttons: (
                <PrimaryButton
                  onClick={() => {
                    history.push('/')
                    dispatch(clearGlobalMessage())
                  }}
                >
                  Ok
                </PrimaryButton>
              )
            })
          )
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: 'ERROR'
            })
          )
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading())
      })
  }

  useEffect(() => {
    fetchProducts()
  }, [pathNav.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />
      <Container>
        <Logo src={LogoHeader} alt='Logo TRB Pharma' />
        <BgProduct className='Product' id='1'>
          <GridTemplate>
            <ProductsContainer>
              <Title>{pathNav.name.split('-').join(' ').toUpperCase()}</Title>
              {products.map(product => {
                return (
                  <Product>
                    <InsertImg delay='200ms'>
                      <BaseImg
                        src={
                          product.image === '' ||
                          product.image === null ||
                          product.image === undefined
                            ? NoImg
                            : 'data:image/jpeg;base64,' + product.image
                        }
                        alt='IMG Teste'
                        draggable={false}
                      />
                    </InsertImg>
                    <ContentText>
                      <TitleRow>
                        <MedicTitle
                          style={{ flex: 1 }}
                          delay='400ms'
                          dangerouslySetInnerHTML={{
                            __html: product.name?.replace('®', '<sup>®</sup>')
                          }}
                        ></MedicTitle>
                        {
                          // product.eam?.includes('789628110051-4') && (
                          //   <ButtonWrap>
                          //     <Locatestore eamProduct={product.eam} />
                          //   </ButtonWrap>
                          // )
                        }
                      </TitleRow>
                      <MedicPrinciple delay='400ms'>
                        {product.principal_active}
                      </MedicPrinciple>
                      <MedicText delay='600ms'>
                        {product.details ? parse(product.details) : ''}
                      </MedicText>

                      <DivButtons
                        dispatch={dispatch}
                        history={history}
                        id={product.id}
                        drugleaflet_label_1={product.drugleaflet_label_1}
                        drugleaflet_label_2={product.drugleaflet_label_2}
                        drugleaflet_label_3={product.drugleaflet_label_3}
                      />
                    </ContentText>
                  </Product>
                )
              })}
            </ProductsContainer>
          </GridTemplate>
        </BgProduct>
      </Container>
      <Footer />
    </>
  )
}

export default ProductRender
