import axios from "axios";
import { MessageModel } from "contracts/app";

class Api {
  private _client = axios.create({
    baseURL: "https://trb-pharma-website-develop.digitaly.tech/api",
  });

  public getInitialMessage() {
    return this._client
      .get<Partial<MessageModel>>("/popup")
      .then(res => res.data)
      .then(v => {
        if (!v.id) {
          return undefined;
        }

        return v as MessageModel;
      });
  }
}

export const api = new Api();
