import styled, { keyframes } from "styled-components";
import { useEffect, useState } from "react";
import Header from "components/Home/Header/Header";
import parse from "html-react-parser";
import GenericButton from "components/Generic/PrimaryButton";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import CardImg from "assets/Product/DefaultImg.png";
import { devices } from "helpers/devices";
import YoutubeEmbed from "components/Generic/YoutubeEmbed";
import { PathContentNav } from "interfaces/PathNav";
import {
  clearGlobalMessage,
  setGlobalMessage,
  toggleGlobalLoading,
} from "redux/actions";
import { axiosWebsite } from "helpers/VariableAmbient";
import { AxiosError, AxiosResponse } from "axios";
import { Segments } from "interfaces/Segment";
import LogoHeader from "assets/LogoHeader.png";
import Footer from "components/Home/Footer/Footer";

const slideImg = keyframes`
    from {
        opacity: 0;
        transform: translateX(-30%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
`;

const slideText = keyframes`
    from {
        opacity: 0;
        transform: translateX(30%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
`;

const Conteiner = styled.section`
  width: 100%;
  height: 100%;
  min-height: 800px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const Logo = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  top: 30px;
  left: 60px;

  @media ${devices.phoneOnly} {
    top: 0px;
    left: 5px;
    width: 100%;
    max-width: 200px;
  }

  @media ${devices.tabletPortrait} {
    top: 10px;
    left: 20px;
    width: 100%;
    max-width: 350px;
  }

  @media ${devices.tabletLandscape} {
    top: 30px;
    left: 50px;
    width: 40%;
    max-width: 350px;
  }

  @media ${devices.desktop} {
    top: 30px;
    left: 60px;
    width: 40%;
    max-width: 450px;
  }

  @media ${devices.bigDesktop} {
    width: 30%;
    max-width: 550px;
  }
`;

const ContentBody = styled.div<{ layout: string }>`
  padding: 0 ${props => props.theme.margin_from_sides};
  display: flex;
  flex-direction: ${props =>
    props.layout === "1"
      ? "row"
      : props.layout === "2"
      ? "row"
      : props.layout === "3"
      ? "column"
      : "0"};
  width: 100%;
  height: 100%;
  padding-top: 10rem;
  overflow-x: hidden;
  overflow-y: auto;

  @media ${devices.phoneOnly} {
    flex-direction: column;
    padding: 0 ${props => props.theme.margin_from_sides_mobile};
    padding-top: 5rem;
  }

  @media ${devices.tabletPortrait} {
    flex-direction: column;
    padding: 0 ${props => props.theme.margin_from_sides_mobile};
    padding-top: 10rem;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: ${props =>
      props.layout === "1"
        ? "row"
        : props.layout === "2"
        ? "row"
        : props.layout === "3"
        ? "column"
        : "0"};
    padding: 0 ${props => props.theme.margin_from_sides};
    padding-top: 10rem;
  }
`;

const DivImg = styled.div<{ layout: string; delay: string }>`
  height: ${props =>
    props.layout === "1"
      ? "100%"
      : props.layout === "2"
      ? "100%"
      : props.layout === "3"
      ? "auto"
      : "0"};
  padding: 1rem;
  animation: ${slideImg} 1s 1 both ${props => props.delay};
`;

const DivText = styled.div<{ delay: string }>`
  width: 100%;
  padding: 1rem;
  animation: ${slideText} 1s 1 both ${props => props.delay};
  margin-bottom: 2rem;
`;

const InsertImg = styled.div<{ layout: string }>`
  width: ${props =>
    props.layout === "1"
      ? "400px"
      : props.layout === "2"
      ? "400px"
      : props.layout === "3"
      ? "100%"
      : "0"};
  height: ${props =>
    props.layout === "1"
      ? "900px"
      : props.layout === "2"
      ? "900px"
      : props.layout === "3"
      ? "auto"
      : "0"};
  overflow: hidden;
  position: relative;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    width: ${props =>
      props.layout === "1"
        ? "100%"
        : props.layout === "2"
        ? "100%"
        : props.layout === "3"
        ? "100%"
        : "0"};
    height: ${props =>
      props.layout === "1"
        ? "250px"
        : props.layout === "2"
        ? "250px"
        : props.layout === "3"
        ? "auto"
        : "0"};
  }

  @media ${devices.tabletPortrait} {
    width: ${props =>
      props.layout === "1"
        ? "100%"
        : props.layout === "2"
        ? "100%"
        : props.layout === "3"
        ? "100%"
        : "0"};
    height: ${props =>
      props.layout === "1"
        ? "250px"
        : props.layout === "2"
        ? "250px"
        : props.layout === "3"
        ? "auto"
        : "0"};
  }

  @media ${devices.tabletLandscape} {
    width: ${props =>
      props.layout === "1"
        ? "250px"
        : props.layout === "2"
        ? "250px"
        : props.layout === "3"
        ? "100%"
        : "0"};
    height: ${props =>
      props.layout === "1"
        ? "700px"
        : props.layout === "2"
        ? "700px"
        : props.layout === "3"
        ? "auto"
        : "0"};
  }

  @media ${devices.desktop} {
    width: ${props =>
      props.layout === "1"
        ? "300px"
        : props.layout === "2"
        ? "300px"
        : props.layout === "3"
        ? "100%"
        : "0"};
    height: ${props =>
      props.layout === "1"
        ? "800px"
        : props.layout === "2"
        ? "800px"
        : props.layout === "3"
        ? "auto"
        : "0"};
  }

  @media ${devices.bigDesktop} {
    width: ${props =>
      props.layout === "1"
        ? "400px"
        : props.layout === "2"
        ? "400px"
        : props.layout === "3"
        ? "100%"
        : "0"};
    height: ${props =>
      props.layout === "1"
        ? "900px"
        : props.layout === "2"
        ? "900px"
        : props.layout === "3"
        ? "auto"
        : "0"};
  }
`;

const BaseImg = styled.img`
  object-fit: fill;
  max-width: 100%;
  max-height: 100%;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: ${props => props.theme.color_text1};
  font-weight: 700;
  margin: 2rem 0;

  @media ${devices.phoneOnly} {
    font-size: 1.8rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 2rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 3rem;
  }
`;

const SubTitle = styled.h1`
  font-size: 1.25rem;
  color: ${props => props.theme.color_text3};
  margin: 2rem 0;

  @media ${devices.phoneOnly} {
    font-size: 1.05rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 1.15rem;
  }

  @media ${devices.tabletLandscape} {
    font-size: 1.25rem;
  }
`;

const YoutubeContainer = styled.div`
  width: 100%;
  height: auto;
  margin: 2rem 0;
`;

const HtmlBody = styled.div`
  width: 100%;
  margin: 2rem 0;
`;

type Layout = "0" | "1" | "2" | "3";

interface Props {
  isHeaderVisible: boolean;
  handleSetHeader: () => void;
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

const ContentRender = ({
  isHeaderVisible,
  handleSetHeader,
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
  fetchSegment,
  segments,
}: Props): JSX.Element => {
  const [layout, setLayout] = useState<Layout>("0");
  const [image, setImage] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubTitle] = useState<string>("");
  const [bodyHtml, setBodyHtml] = useState<string>("");
  const [video, setVideo] = useState<string>("");
  // const [imageName, setImageName] = useState<string>("");
  // const [status, setStatus] = useState<string>("");
  // const [segment, setSegment] = useState<string>("");
  // const [specialty, setSpecialty] = useState<string>("");
  // const [product, setProduct] = useState<string>("");
  // const [linkContent, setLinkContent] = useState<string>("");
  const dispatch = useDispatch();
  const history = useHistory();
  const pathNav = useParams<PathContentNav>();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const handleSetMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const fetchContent = () => {
    dispatch(toggleGlobalLoading());

    axiosWebsite
      .get(`/content-site/${pathNav.id}`)
      .then((response: AxiosResponse) => {
        setLayout(response.data.data.layout);
        setImage(response.data.data.image);
        setTitle(response.data.data.title);
        setSubTitle(response.data.data.subtitle);
        setBodyHtml(response.data.data.body);
        setVideo(response.data.data.video);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
              buttons: (
                <GenericButton
                  onClick={() => {
                    history.push("/");
                    dispatch(clearGlobalMessage());
                  }}
                >
                  Ok
                </GenericButton>
              ),
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
            })
          );
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading());
      });
  };

  useEffect(() => {
    fetchContent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />
      <Logo src={LogoHeader} alt="Logo TRB Pharma" />
      <Conteiner>
        {layout === "1" ? (
          <ContentBody layout={layout}>
            <DivImg delay="100ms" layout={layout}>
              <InsertImg layout={layout}>
                <BaseImg
                  src={
                    image === "" || image === null || image === undefined
                      ? CardImg
                      : "data:image/jpeg;base64," + image
                  }
                  alt="IMG Teste"
                  draggable={false}
                />
              </InsertImg>
            </DivImg>
            <DivText delay="200ms">
              {title !== "" ? <Title>{title}</Title> : null}
              {subTitle !== "" ? <SubTitle>{subTitle}</SubTitle> : null}
              {video !== "" ? (
                <YoutubeContainer>
                  <YoutubeEmbed link={video ? video : ""} />
                </YoutubeContainer>
              ) : null}
              {bodyHtml !== "" ? (
                <HtmlBody>{parse(bodyHtml ? bodyHtml : "")} </HtmlBody>
              ) : null}
            </DivText>
          </ContentBody>
        ) : layout === "2" ? (
          <ContentBody layout={layout}>
            <DivText delay="100ms">
              {title !== "" ? <Title>{title}</Title> : null}
              {subTitle !== "" ? <SubTitle>{subTitle}</SubTitle> : null}
              {video !== "" ? (
                <YoutubeContainer>
                  <YoutubeEmbed link={video ? video : ""} />
                </YoutubeContainer>
              ) : null}
              {bodyHtml !== "" ? (
                <HtmlBody>{parse(bodyHtml ? bodyHtml : "")} </HtmlBody>
              ) : null}
            </DivText>
            <DivImg delay="200ms" layout={layout}>
              <InsertImg layout={layout}>
                <BaseImg
                  src={
                    image === "" || image === null || image === undefined
                      ? CardImg
                      : "data:image/jpeg;base64," + image
                  }
                  alt="IMG Teste"
                  draggable={false}
                />
              </InsertImg>
            </DivImg>
          </ContentBody>
        ) : (
          <ContentBody layout={layout}>
            <DivImg delay="100ms" layout={layout}>
              <InsertImg layout={layout}>
                <BaseImg
                  src={
                    image === "" || image === null || image === undefined
                      ? CardImg
                      : "data:image/jpeg;base64," + image
                  }
                  alt="IMG Teste"
                  draggable={false}
                />
              </InsertImg>
            </DivImg>
            <DivText delay="200ms">
              {title !== "" ? <Title>{title}</Title> : null}
              {subTitle !== "" ? <SubTitle>{subTitle}</SubTitle> : null}
              {video !== "" ? (
                <YoutubeContainer>
                  <YoutubeEmbed link={video ? video : ""} />
                </YoutubeContainer>
              ) : null}
              {bodyHtml !== "" ? (
                <HtmlBody>{parse(bodyHtml ? bodyHtml : "")} </HtmlBody>
              ) : null}
            </DivText>
          </ContentBody>
        )}
      </Conteiner>
      <Footer />
    </>
  );
};

export default ContentRender;
