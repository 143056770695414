import { devices } from "helpers/devices";
import { Segments } from "interfaces/Segment";
import { useState } from "react";
import { ReactComponent as IconMenu } from "assets/IconMenu.svg";
import styled from "styled-components";
import PharmacovigilanceRender from "components/Pharmacovigilance/Pharmacovigilance";
import BgAreas from "assets/bgareas.png";

const Container = styled.section`
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  background: url("${BgAreas}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;

const BgWhite = styled.div`
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;
`;

const ButtonMenu = styled.button<{ Fill?: boolean }>`
  border: none;
  background: transparent;
  position: fixed;
  z-index: 13;
  top: 0;
  right: 0;
  margin: 0.7rem;
  display: none;

  & svg {
    width: 30px;
    height: 30px;
    fill: ${props =>
      props.Fill
        ? props.theme.color_secondary_main
        : props.theme.color_primary_main};
  }

  @media ${devices.phoneOnly} {
    display: block;
  }

  @media ${devices.tabletPortrait} {
    display: block;
  }

  @media ${devices.desktop} {
    display: none;
  }
`;

interface Props {
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

const Pharmacovigilance = ({
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
  fetchSegment,
  segments,
}: Props): JSX.Element => {
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);
  const handleSetHeader = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };

  return (
    <Container>
      <ButtonMenu Fill={isHeaderVisible} onClick={() => handleSetHeader()}>
        <IconMenu />
      </ButtonMenu>
      <BgWhite>
        <PharmacovigilanceRender
          isHeaderVisible={isHeaderVisible}
          handleSetHeader={() => setIsHeaderVisible(!isHeaderVisible)}
          segmentName={segmentName}
          segments={segments}
          segmentId={segmentId}
          selectedSegmentName={selectedSegmentName}
          selectedSegment={selectedSegment}
          fetchSegment={fetchSegment}
        />
      </BgWhite>
      {/* <Footer /> */}
    </Container>
  );
};

export default Pharmacovigilance;
