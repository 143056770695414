import { useSelector } from "react-redux";
import { GlobalState } from "redux/types";
import styled, { keyframes } from "styled-components";

const slide = keyframes`
  to {
    transform: rotate(1turn);
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 500;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Effect = styled.div`
  border: 15px solid #e5e5e5;
  border-radius: 50%;
  border-top-color: ${props => props.theme.color_primary_main};
  height: 150px;
  width: 150px;
  animation: ${slide} 1s infinite;
`;

const GlobalLoading = (): JSX.Element | null => {
  const isLoading = useSelector((state: GlobalState) => state.globalLoading);

  if (isLoading) {
    return (
      <Background>
        <Effect />
      </Background>
    );
  } else {
    return null;
  }
};
export default GlobalLoading;
