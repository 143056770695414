export const size = {
    phoneOnly: 599,
    tabletPortrait: 600,
    tabletLandscape: 900,
    desktop: 1200,
    bigDesktop: 1800,
};

export const devices = {
    phoneOnly: `(max-width: ${size.phoneOnly}px)`,
    tabletPortrait: `(min-width: ${size.tabletPortrait}px)`,
    tabletLandscape: `(min-width: ${size.tabletLandscape}px)`,
    desktop: `(min-width: ${size.desktop}px)`,
    bigDesktop: `(min-width: ${size.bigDesktop}px)`,
};
