import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import parse from "html-react-parser";
import type { AxiosError, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import type { ProductsResponse } from "../@types";
import { axiosWebsite } from "helpers/VariableAmbient";
import Filter from "./Filter";
import { Locatestore } from "./Locatestore";
import { Catalog } from "../Catalog.styles";
import { setCatalog, toggleGlobalLoading } from "redux/actions";
import PrimaryButton from "components/Generic/PrimaryButton";
import { clearGlobalMessage, setGlobalMessage } from "redux/actions";
import { toSlug } from "helpers/toSlug";

type TAG = keyof typeof TAG_BACKGROUND;

const TAG_BACKGROUND = {
  "ISENTO DE PRESCRIÇÃO": "#737373",
  "PRESCRIÇÃO MÉDICA": "#b20404",
  "PRODUTO PARA SAÚDE": "#008000",
};

interface AccString {
  [key: string]: number;
}

type ListProps = {
  onClick: (id_segment: string, eam: string[]) => void;
};

const List = ({ onClick }: ListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dataCatalog, setDataCatalog] = useState<ProductsResponse["data"]>([]);
  const [prevCatalog, setPrevCatalog] = useState<ProductsResponse["data"]>([]);
  const [dataFilter, setDataFilter] = useState<AccString>();
  const [filter, setFilter] = useState("todos");
  const [total, setTotal] = useState(0);

  const { type } = useParams<any>();

  useEffect(() => {
    console.log({ type });
  }, [type]);

  useEffect(() => {
    dispatch(toggleGlobalLoading());

    axiosWebsite
      .get("https://trb-pharma-website.digitaly.tech/api/products/site")
      .then((response: AxiosResponse<ProductsResponse>) => {
        const {
          data: { data },
        } = response;

        setDataCatalog(data);
        setPrevCatalog(data);
        dispatch(setCatalog(data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
              buttons: (
                <PrimaryButton
                  onClick={() => {
                    history.push("/");
                    dispatch(clearGlobalMessage());
                  }}
                >
                  Ok
                </PrimaryButton>
              ),
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
            })
          );
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataCatalog) {
      const count = dataCatalog.reduce((acc, curr) => {
        if (!curr.eam.length) {
          return acc;
        }

        acc[curr.segment] = acc[curr.segment] ? acc[curr.segment] : 0;
        acc[curr.segment]++;

        setTotal(prev => prev + acc[curr.segment]);

        return acc;
      }, {} as AccString);

      const totals = Object.entries(count).reduce((acc, [_, value], index) => {
        return acc + value;
      }, 0);

      setTotal(totals);

      setDataFilter(count);
    }
  }, [dataCatalog]);

  useEffect(() => {
    const filter = type || "todos";

    if (filter !== "todos") {
      const teste = dataCatalog.filter(item => {
        return toSlug(item.segment) === filter;
      });
      setPrevCatalog(teste);
    } else {
      setPrevCatalog(dataCatalog);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <>
      <Catalog.List>
        <Filter onClick={setFilter} total={total} segments={dataFilter} />

        {prevCatalog?.map(item => {
          const { eam, type_prescription, product, resumo, id_segment } = item;

          if (!Object.keys(eam).length) {
            return null;
          }

          return (
            <Catalog.Col key={eam[0]}>
              <Catalog.Detail
                onClick={() => {
                  history.push(`/comprar/${id_segment}/${eam.join("__")}`);
                }}
              >
                <Catalog.Tag
                  style={{
                    background: TAG_BACKGROUND[type_prescription as TAG],
                  }}
                >
                  {type_prescription}
                </Catalog.Tag>
                <Catalog.Title>
                  {parse(product.replace("®", "<sup>®</sup>"))}
                </Catalog.Title>
                <Catalog.Resumo>{parse(resumo)}</Catalog.Resumo>
              </Catalog.Detail>
              <Locatestore eamProduct={eam} />
            </Catalog.Col>
          );
        })}
      </Catalog.List>
    </>
  );
};

export default List;
