import React from "react";

import LogoHeader from "assets/LogoHeader.png";

import { Logo } from "./Logo.styles";

const LogoBase = () => {
  return <Logo.Image src={LogoHeader} alt="Logo TRB Pharma" />;
};

export default LogoBase;
