import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Breadcrumb } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { GlobalState } from "redux/types";
import { toggleCatalog } from "redux/actions";

import { ProductProps } from "./Product/Product.d";
import { Catalog } from "./Catalog.styles";
import { List } from "./components";
import { Product } from "./Product";

const ContainerCatalog = () => {
  const dispatch = useDispatch();
  const isCatalog = useSelector((state: GlobalState) => state.catalog);
  const [segment, setSegment] = useState<ProductProps["segment"]>({
    segmentId: "1",
    ean: [],
  });

  const handleClickDetail = () => {
    dispatch(toggleCatalog(false));
  };

  const handleClick = (segmentId: string, ean: string[]) => {
    setSegment({
      segmentId,
      ean,
    });

    dispatch(toggleCatalog(true));
  };

  return (
    <Catalog.Container>
      {isCatalog ? (
        <Product segment={segment} onClick={handleClickDetail} />
      ) : (
        <>
          <Catalog.Breadcrumb>
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item>Produtos</Breadcrumb.Item>
          </Catalog.Breadcrumb>

          <List onClick={handleClick} />
        </>
      )}
    </Catalog.Container>
  );
};

export default ContainerCatalog;
