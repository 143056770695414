import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

interface ModalProps {
  children: React.ReactElement;
  visible: boolean;
  onClick: () => void;
}

const ContainerModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    border: 0;
    background-color: #fafafa;
  }

  .modal-body {
    background: #f6f6f6;
    max-height: 650px;
    overflow-y: auto;
    padding: 0;
  }

  .modal-header {
    border: 0;
    justify-content: center;
  }

  .modal-title {
    font-size: 1.2em;
    justify-content: center;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }

  .close {
    align-items: center;
    background-color: #fafafa;
    border-radius: 100px;
    border: 0;
    color: #787878;
    display: flex;
    font-size: 33px;
    font-weight: 700;
    height: 40px;
    justify-content: space-around;
    line-height: 43px;
    position: absolute;
    right: -21px;
    top: -17px;
    width: 40px;

    span {
      height: 50px;

      &.sr-only {
        display: none;
      }
    }
  }
`;

const ModalBase = ({ children, visible, onClick }: ModalProps) => {
  return (
    <ContainerModal show={visible} onHide={onClick} className="silvano">
      <Modal.Header closeButton>
        <Modal.Title>Onde Comprar este produto</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
    </ContainerModal>
  );
};

export default ModalBase;
