import axios, { AxiosError } from "axios";

const checkAxiosError = (error: any): error is AxiosError => {
    if (axios.isAxiosError(error) && error.response) {
        return true;
    } else {
        console.warn(
            `Objeto '${error}' não retornou resposta do servidor ou não é um erro do Axios.`
        );
        return false;
    }
};

/**
 * Verifica se o usuário está autorizado a fazer a operação no sistema.
 * @param error Qualquer objeto para ser checado.
 * @returns {boolean} O usuário está ou não autorizado a fazer a operação.
 */
export const isUnauthorized = (error: any): boolean => {
    if (checkAxiosError(error)) {
        switch (error.response!.status) {
            case 401:
            case 403:
                return true;
            default:
                return false;
        }
    } else {
        return false;
    }
};

/**
 * Verifica se o servidor retornou resposta vazia.
 * @param error Qualquer objeto para ser checado.
 * @returns {boolean} O servidor retornou uma resposta vazia.
 */
export const isNoContent = (error: any): boolean => {
    if (checkAxiosError(error)) {
        switch (error.response!.status) {
            case 204:
                return true;
            default:
                return false;
        }
    } else {
        return false;
    }
};

/**
 * Verifica se o servidor recusou a adição do conteudo porque houve um conflito. Ex. Nome já cadastrado.
 * @param error Qualquer objeto para ser checado.
 * @returns {boolean} Houve ou não um conflito.
 */
export const isConflicting = (error: any): boolean => {
    if (checkAxiosError(error)) {
        if (error.response!.status === 409) {
            return true;
        } else if (error.response!.data.stack.includes("Validation error")) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * Verifica erros do cliente em geral.
 * @param error Qualquer objeto para ser checado.
 * @returns {boolean} Se houve um erro do cliente.
 */
export const isClientError = (error: any): boolean => {
    if (checkAxiosError(error)) {
        if (error.response!.status >= 400 && error.response!.status < 500) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};

/**
 * Verifica erros do servidor em geral.
 * @param error Qualquer objeto para ser checado.
 * @returns {boolean} Se houve um erro do servidor.
 */
export const isServerError = (error: any): boolean => {
    if (checkAxiosError(error)) {
        if (error.response!.status >= 500 && error.response!.status < 600) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
