import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as IconMenu } from "assets/IconMenu.svg";
import { devices } from "helpers/devices";
import { Segments } from "interfaces/Segment";
import ContentRender from "components/Home/Blog/ContentBlog/Content";

const Container = styled.section`
  position: relative;
`;

const ButtonMenu = styled.button<{ Fill?: boolean }>`
  border: none;
  background: transparent;
  position: fixed;
  z-index: 13;
  top: 0;
  right: 0;
  margin: 0.7rem;
  display: none;

  & svg {
    width: 30px;
    height: 30px;
    fill: ${props =>
      props.Fill
        ? props.theme.color_secondary_main
        : props.theme.color_primary_main};
  }

  @media ${devices.phoneOnly} {
    display: block;
  }

  @media ${devices.tabletPortrait} {
    display: block;
  }

  @media ${devices.desktop} {
    display: none;
  }
`;

interface Props {
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

const Content = ({
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
  fetchSegment,
  segments,
}: Props): JSX.Element => {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);
  const handleSetHeader = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };

  return (
    <Container>
      <ButtonMenu Fill={isHeaderVisible} onClick={() => handleSetHeader()}>
        <IconMenu />
      </ButtonMenu>
      <ContentRender
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={() => setIsHeaderVisible(!isHeaderVisible)}
        segmentName={segmentName}
        segments={segments}
        segmentId={segmentId}
        selectedSegmentName={selectedSegmentName}
        selectedSegment={selectedSegment}
        fetchSegment={fetchSegment}
      />
    </Container>
  );
};

export default Content;
