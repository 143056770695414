import { useState } from "react";

export function useHeaderVisible() {
  const [isHeaderVisible, setIsHeaderVisible] = useState(false);

  const handleSetHeader = () => {
    setIsHeaderVisible(!isHeaderVisible);
  };

  return {
    isHeaderVisible,
    handleSetHeader,
  };
}
