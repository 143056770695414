import styled from "styled-components";
import BgMissao from "assets/BgMissao.png";
import { devices } from "helpers/devices";

const Container = styled.section`
  background-image: url("${BgMissao}");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${props => props.theme.color_secondary_light};

  @media ${devices.phoneOnly} {
    background-image: none;
  }

  @media ${devices.tabletPortrait} {
    background-image: none;
  }

  @media ${devices.tabletLandscape} {
    background-image: url("${BgMissao}");
  }
`;

const GridTemplate = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  width: 100%;

  @media ${devices.phoneOnly} {
    display: flex;
    flex-direction: column;
    padding: 5rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.tabletPortrait} {
    display: flex;
    flex-direction: column;
    padding: 5rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.tabletLandscape} {
    display: grid;
    padding: 5rem ${props => props.theme.margin_from_sides};
  }

  @media ${devices.desktop} {
    padding: 5rem calc(${props => props.theme.margin_from_sides} + 4rem);
  }

  @media ${devices.bigDesktop} {
    padding: 4rem ${props => props.theme.margin_from_sides};
  }
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  grid-column-start: 1;
`;

const Title = styled.h2`
  margin-top: 0.5rem;
  color: ${props => props.theme.color_text1};

  @media ${devices.phoneOnly} {
    font-size: 1.2rem;
  }
`;

const Text = styled.p`
  margin-top: 0.5rem;
  color: ${props => props.theme.gray3};

  @media ${devices.phoneOnly} {
    font-size: 0.9rem;
  }
`;

const Missao = (): JSX.Element => (
  <Container className="Missao" id="3">
    <GridTemplate>
      <TextGroup>
        <div>
          <Title>Missão</Title>
          <Text>
            Em sua trajetória a TRB PHARMA tem como missão obter desenvolvimento
            terapêutico inovador para o tratamento de um leque de doenças
            sempre, nos mais altos padrões legais e éticos, tendo o controle
            rigoroso como marco de sua qualidade.
          </Text>
        </div>
        <div>
          <Title>Visão</Title>
          <Text>
            Visando crescimento sustentável, agrega valor socioambiental
            minimizando qualquer dano resultante de sua atuação.
          </Text>
        </div>
        <div>
          <Title>Valores</Title>
          <Text>
            Para a TRB PHARMA inovar com rigor em estratégias terapêuticas é
            fundamental. Potencializar a vida também. Mantendo sustentabilidade
            sempre. Tecendo no dia a dia ciência e saude como princípio.
          </Text>
        </div>
        <div>
          <Title>Responsabilidade Ambiental</Title>
          <Text>
            A TRB Pharma entende que o crescimento econômico deve estar ligado à
            preservação do meio ambiente e a sustentabilidade social. Visando
            crescimento sustentável, agrega valor socioambiental, minimizando
            qualquer dano de sua atuação. Compromisso assumido com ética,
            transparência e profissionalismo.
          </Text>
        </div>
      </TextGroup>
    </GridTemplate>
  </Container>
);

export default Missao;
