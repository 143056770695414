import styled from "styled-components";
import { devices } from "helpers/devices";
import PrimaryButton from "components/Generic/PrimaryButton";

const variantStyled = {
  color: "#6c757d",
};

export const Product = {
  ArrowLeft: styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 3em;
    width: 100%;

    span {
      color: ${variantStyled.color};
      display: inline-block;
      font-size: 1.7em;
      margin-right: 14px;
      transform: rotate(180deg);
    }
  `,

  Button: styled(PrimaryButton)`
    margin: 1em 1em 1em 0;
    border-radius: 25px;
    background: ${props => props.theme.color_secondary_dark};

    @media ${devices.phoneOnly} {
      font-size: 0.7rem;
      padding: 1rem;
      min-width: 80px;
    }
  `,

  Container: styled.div`
    display: grid;

    @media ${devices.phoneOnly} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    @media ${devices.tabletPortrait} {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    @media ${devices.tabletLandscape} {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    @media ${devices.bigDesktop} {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, 1fr);
    }

    img {
      background: transparent;
      height: 400px;
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      overflow: hidden;
      position: relative;
      width: 400px;

      @media ${devices.phoneOnly} {
        margin: 0 auto;
      }

      @media ${devices.tabletPortrait} {
        margin: 0 auto;
      }
    }
  `,

  Details: styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 3em;

    @media ${devices.phoneOnly} {
      margin: 0;
    }

    @media ${devices.tabletPortrait} {
      margin: 0;
    }

    @media ${devices.bigDesktop} {
      margin-left: 3em;
    }

    h1 {
      color: rgb(61, 61, 61);
      font-size: 2rem;
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      white-space: pre-wrap;
      max-width: 900px;
    }

    p {
      width: 100%;
      align-self: flex-start;
      overflow: hidden;
      white-space: pre-wrap;
      max-width: 900px;
      margin-bottom: 0.5em;

      br {
        display: none;
      }
    }
  `,

  DivButton: styled.div`
    display: flex;
    align-items: center;

    @media ${devices.phoneOnly} {
      flex-direction: column;
    }

    @media ${devices.tabletLandscape} {
      display: -webkit-box;
    }

    @media ${devices.bigDesktop} {
      display: -webkit-box;
    }

    button {
      width: auto;

      &.btn-lg {
        max-width: 480px;
        min-width: 300px;
        height: 50px;
        text-align: center;
        justify-content: center;
      }
    }

    > div {
      @media ${devices.phoneOnly} {
        justify-content: space-between;
        width: 100%;
      }

      @media ${devices.tabletPortrait} {
        margin-right: 1em;
      }

      button {
        background: transparent;
        color: #1a7e2e;
        border: 2px solid #1a7e2e;
        padding: 0.6rem 2.4rem;

        @media ${devices.phoneOnly} {
          padding: 1em;
          margin: 1em 0;
          min-width: 130px;
        }

        @media ${devices.tabletPortrait} {
          padding: 1em;
          margin: 1em;
        }

        @media ${devices.bigDesktop} {
          padding: 0.6rem 2.4rem;
          margin-left: 0;
        }

        &:hover {
          background-color: #1a7e2e1c;
        }
      }
    }
  `,
};
