import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "Carousel.css";
import {
  createGlobalStyle,
  ThemeProvider,
  DefaultTheme,
} from "styled-components";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { globalReducer } from "redux/globalReducer";

const globalStore = createStore(
  globalReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

const GlobalStyle = createGlobalStyle`
    * {
        padding: 0;
        margin: 0;
        box-sizing: border-box;
    }
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: #828282;
    }
    body {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
`;

const theme: DefaultTheme = {
  color_primary_main: "#005995",
  color_primary_light: "#1281ad",
  color_primary_dark: "#004b87",
  color_primary_dark2: "#042339",
  color_secondary_dark: "#1a7e2e",
  color_secondary_dark2: "#37cc55",
  color_secondary_main: "#55bdb2",
  color_secondary_light: "#ccdeea",
  color_text1: "#005995",
  color_text2: "#FFF",
  color_text3: "#3d3d3d",
  color_approval: "#14ad33",
  color_deny: "#bb1818",
  button_normal: "#55BDB2",
  button_deny: "#ff6e6e",
  button_warn: "#eecb1d",
  button_normal_hover: "#64dace",
  button_deny_hover: "#f88484",
  button_warn_hover: "#ffd500",
  button_yellow_link: "#fff200",
  white1: "#FFF",
  white2: "#f2f2f2",
  white3: "#ddd",
  white4: "#dce3ed",
  white5: "#b4b9bd",
  gray1: "#042031",
  gray2: "#111",
  gray3: "#323232",
  gray4: "#a0a5a2",
  transparent_opaque: "rgba(24, 24, 24, 0.8)",
  transparent_clear: "rgba(24, 24, 24, 0.5)",
  margin_from_sides: "4rem",
  margin_from_sides_mobile: "2rem",
  font_huge: "2.5rem",
  font_large: "1.5rem",
  font_regular: "1.3rem",
  font_medium: "1rem",
  font_small: ".8rem",
  font_very_small: ".4rem",
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Provider store={globalStore}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
