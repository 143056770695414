import React, { useState } from "react";
import type { AxiosError, AxiosResponse } from "axios";

import type { LocatestoreResponse } from "./Locatestore.d";
import { axiosLocatestore } from "helpers/VariableAmbient";
import { Button } from "../Button";

import Modal from "../Modal";
import Retailer from "../Retailer";

const Locatestore = ({ eamProduct }: { eamProduct: string[] }) => {
  const [dataModal, setModalCatalog] = useState<LocatestoreResponse>();

  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = (eam: string[]) => {
    if (Object.keys(eam).length) {
      setLoading(true);

      axiosLocatestore
        .get(
          `https://locatestore.intellibrand.ai/products?products=${eam.join()}`
        )
        .then((response: AxiosResponse<LocatestoreResponse>) => {
          const { data } = response;

          data.data = data.data.map(nextData => {
            nextData.offers = nextData.offers.filter(offer => {
              return offer.price && offer.price > 0;
            });

            return nextData;
          });

          setModalCatalog(data);
          setLoading(false);
          setShow(true);
        })
        .catch((error: AxiosError) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Button
        onClick={handleShow}
        isLoading={isLoading}
        productCode={eamProduct}
      />

      {dataModal && (
        <Modal onClick={handleClose} visible={show}>
          <Retailer {...dataModal} />
        </Modal>
      )}
    </>
  );
};

export default Locatestore;
