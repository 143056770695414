import { AxiosError } from "axios";
import { ProductsResponse } from "components/Catalog/@types";
import PrimaryButton from "components/Generic/PrimaryButton";
import { error } from "console";
import { axiosWebsite } from "helpers/VariableAmbient";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  clearGlobalMessage,
  setCatalog,
  setGlobalMessage,
  toggleGlobalLoading,
} from "redux/actions";
import { GlobalState } from "redux/types";

export function useCatalogQuery() {
  const history = useHistory();
  const dispatch = useDispatch();
  const catalogList = useSelector((state: GlobalState) => state.catalogList);

  const [isLoading, setLoading] = useState(false);

  const reload = () => {
    dispatch(toggleGlobalLoading());

    setLoading(() => true);
    console.log("carregaaaaaaaa");
    return axiosWebsite
      .get<ProductsResponse>(
        "https://trb-pharma-website.digitaly.tech/api/products/site"
      )
      .then(response => {
        const {
          data: { data },
        } = response;
        console.log("finalizad");

        dispatch(setCatalog(data));
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 403) {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
              buttons: (
                <PrimaryButton
                  onClick={() => {
                    history.push("/");
                    dispatch(clearGlobalMessage());
                  }}
                >
                  Ok
                </PrimaryButton>
              ),
            })
          );
        } else {
          dispatch(
            setGlobalMessage({
              message: `Error: ${error.response?.status}`,
              type: "ERROR",
            })
          );
        }
      })
      .finally(() => {
        dispatch(toggleGlobalLoading());
        setLoading(() => false);
      });
  };

  useEffect(() => {}, []);

  return {
    catalog: catalogList,
    isLoading,
    reload,
  };
}
