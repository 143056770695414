import React from "react";
import { ReactComponent as IconMenu } from "assets/IconMenu.svg";

import { ButtonMenu } from "./ButtonMenu.styles";

type ButtonMenuProps = {
  isHeaderVisible: boolean;
  onClick: () => void;
};

const ButtonMenuBase = ({ isHeaderVisible, onClick }: ButtonMenuProps) => {
  return (
    <ButtonMenu Fill={isHeaderVisible} onClick={onClick}>
      <IconMenu />
    </ButtonMenu>
  );
};

export default ButtonMenuBase;
