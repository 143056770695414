//Telefone
export const formatPhone = (phone: string) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d{4})(\d{4})$/, "$1-$2");
  return String(phone);
};

export const formatCelPhone = (phone: string) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/(\d{2})(\d)/, "($1) $2");
  phone = phone.replace(/(\d{5})(\d{4})$/, "$1-$2");
  return String(phone);
};

//Datas
export const formatDate = (data: string) => {
  const newData = data.substr(0, 10).split("-").reverse().join("/");
  return newData;
};

export const unFormatDate = (data: string) => {
  const newData = data.substr(0, 10).split("/").reverse().join("-");
  return newData;
};

export const unFormatDateMonth = (data: string) => {
  const newData = data.substr(0, 7).split("/").reverse().join("-");
  return newData;
};

export const formatDateMonth = (data: string) => {
  const newData = data.substr(0, 7).split("-").reverse().join("/");
  return newData;
};

export const formatDateHour = (data: string) => {
  const newData = data.substr(0, 10).split("-").reverse().join("/");
  const newHour = data.substr(11, 5);
  return newData + " - " + newHour;
};

export const formatDateAgo = (data: string) => {
  const newDate = new Date();
  const dateAgo = new Date(data);

  let mileSeconds = newDate.getTime() - (dateAgo.getTime() + 10800000);
  let seconds = Math.floor(mileSeconds / 1000);
  let minutes = Math.floor(mileSeconds / (1000 * 60));
  let hours = Math.floor(mileSeconds / (1000 * 60 * 60));
  let days = Math.floor(mileSeconds / (1000 * 60 * 60 * 24));

  if (Number(seconds) < 60) return seconds + " Seg";
  else if (Number(minutes) < 60) return minutes + " Min";
  else if (Number(hours) < 24) return hours + " Hrs";
  else return days + " Dias";
};

/* const maskDate = value => {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d)/, "$1");
  }; */

//Cep
export const formatCEP = (cep: string) => {
  cep = cep.replace(/\D/g, "");
  cep = cep.replace(/(\d{5})(\d{3})$/, "$1-$2");
  return String(cep);
};

// RG
export const formatRG = (rg: string) => {
  rg = rg.replace(/\D/g, ""); //Substituí o que não é dígito por "", /g é [Global][1]
  rg = rg.replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
  return String(rg);
};
// CPF
export const formatCPF = (cpf: string) => {
  cpf = cpf.replace(/\D/g, "");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  return String(cpf);
};

export const formatCNPJ = (cnpj: string) => {
  cnpj = cnpj.replace(/\D/g, "");
  cnpj = cnpj.replace(/(\d{2})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1.$2");
  cnpj = cnpj.replace(/(\d{3})(\d)/, "$1/$2");
  cnpj = cnpj.replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  return String(cnpj);
};

export const formatPercentage = (value: string) => {
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d{2})$/, "$1");
  return String(value);
};

export const formatMoney = (value: string) => {
  value = value.replace(/\D/g, "");
  // value = Number(value).toFixed(2);
  // value = value.replace(/\d(?=(\d{3})+\.)/g, "$&.");

  return String(value);
};

export const formatAmount = (value: number) => {
  const amount = new Intl.NumberFormat("pt-BR", {
    currency: "BRL",
    style: "currency",
  });

  return amount.format(value);
};

export const formatAgency = (value: string) => {
  value = value.replace(/\D/g, "");
  // value = Number(value).toFixed(2);
  // value = value.replace(/\d(?=(\d{3})+\.)/g, "$&.");

  return String(value);
};

export const formatAccountBank = (value: string) => {
  value = value.replace(/\D/g, "");
  // value = Number(value).toFixed(2);
  // value = value.replace(/\d(?=(\d{3})+\.)/g, "$&.");

  return String(value);
};
