import { formatDate } from "./../helpers/formats";
import { sendMail } from "helpers/VariableAmbient";
export interface FormPharmacovigilance {
  emails?: string[];
  date?: string;
  name?: string;
  email?: string;
  contactPhone?: string;
  profession?: string;
  zipcode?: string;
  city?: string;
  state?: string;
  initialsTheSame?: string;
  initials?: string;
  alreadyUse?: string;
  hadDisease?: string;
  gender?: string;
  weight?: string;
  age?: string;
  height?: string;
  productName?: string;
  lotNumber?: string;
  expirationDate?: string;
  dosage?: string;
  frequency?: string;
  consumption?: string;
  recommendation?: string;
  startOfTreatment?: string;
  endOfTreatment?: string;
  endOfTreatmentTheSame?: string;
  concomitantMedicationsTheSame?: string;
  concomitantDiseasesTheSame?: string;
  concomitantMedicationsOne?: string;
  recommendationOne?: string;
  startDateOne?: string;
  endDateOne?: string;
  endDateOneTheSame?: string;
  dailyDoseOne?: string;
  concomitantMedicationsTwo?: string;
  recommendationTwo?: string;
  startDateTwo?: string;
  endDateTwo?: string;
  endDateTwoTheSame?: string;
  dailyDoseTwo?: string;
  concomitantMedicationsThree?: string;
  recommendationThree?: string;
  startDateThree?: string;
  endDateThree?: string;
  endDateThreeTheSame?: string;
  dailyDoseThree?: string;
  concomitantMedicationsFour?: string;
  recommendationFour?: string;
  startDateFour?: string;
  endDateFour?: string;
  endDateFourTheSame?: string;
  dailyDoseFour?: string;
  concomitantMedicationsFive?: string;
  recommendationFive?: string;
  startDateFive?: string;
  endDateFive?: string;
  endDateFiveTheSame?: string;
  dailyDoseFive?: string;
  concomitantDiseases?: string;
  doctorName?: string;
  councilCode?: string;
  doctorPhone?: string;
  suspended?: string;
  startReaction?: string;
  endReaction?: string;
  endReactionTheSame?: string;
  durationReaction?: string;
  recoveredTheSame?: string;
  recovered?: string;
  adverseReactions?: string;
  adverseReactionsTheSame?: string;
  acquiredWhere?: string;
  fiscalNote?: string;
  canContact?: string;
  observation?: string;
}

export const InitFormPharmacovigilance = {
  emails: [sendMail],
  date: formatDate(String(new Date().toISOString().slice(0, 10))),
  name: "",
  email: "",
  contactPhone: "",
  profession: "",
  zipcode: "",
  city: "",
  state: "",
  initialsTheSame: "",
  initials: "",
  alreadyUse: "",
  hadDisease: "",
  gender: "",
  weight: "",
  age: "",
  height: "",
  productName: "",
  lotNumber: "",
  expirationDate: "",
  dosage: "",
  frequency: "",
  consumption: "",
  recommendation: "",
  startOfTreatment: "",
  endOfTreatment: "",
  endOfTreatmentTheSame: "",
  concomitantMedicationsTheSame: "",
  concomitantDiseasesTheSame: "",
  concomitantMedicationsOne: "",
  recommendationOne: "",
  startDateOne: "",
  endDateOne: "",
  endDateOneTheSame: "",
  dailyDoseOne: "",
  concomitantMedicationsTwo: "",
  recommendationTwo: "",
  startDateTwo: "",
  endDateTwo: "",
  endDateTwoTheSame: "",
  dailyDoseTwo: "",
  concomitantMedicationsThree: "",
  recommendationThree: "",
  startDateThree: "",
  endDateThree: "",
  endDateThreeTheSame: "",
  dailyDoseThree: "",
  concomitantMedicationsFour: "",
  recommendationFour: "",
  startDateFour: "",
  endDateFour: "",
  endDateFourTheSame: "",
  dailyDoseFour: "",
  concomitantMedicationsFive: "",
  recommendationFive: "",
  startDateFive: "",
  endDateFive: "",
  endDateFiveTheSame: "",
  dailyDoseFive: "",
  concomitantDiseases: "",
  doctorName: "",
  councilCode: "",
  doctorPhone: "",
  suspended: "",
  startReaction: "",
  endReaction: "",
  endReactionTheSame: "",
  durationReaction: "",
  recoveredTheSame: "",
  recovered: "",
  adverseReactions: "",
  adverseReactionsTheSame: "",
  acquiredWhere: "",
  fiscalNote: "",
  canContact: "",
  observation: "",
};
