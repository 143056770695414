import { base64toBlob } from "./base64toBlob";

export const openPdf = (data: string) => {
  let blob = base64toBlob(data);
  const nav = window.navigator as any;
  if (nav && nav.msSaveOrOpenBlob) {
    nav.msSaveOrOpenBlob(blob, `${Date.now()}.pdf`);
  } else {
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl);
  }
};
