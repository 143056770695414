import { useHistory, useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { devices } from 'helpers/devices'
import SubMenu from './SubMenu'
import { ReactComponent as IconMenu } from 'assets/IconMenu.svg'
//import components to scroller
import Scroll from 'react-scroll'
import { useEffect } from 'react'
import { Segments } from 'interfaces/Segment'
import { Catalog } from 'components/Catalog/components/Button/Button.styles'

const scroller = Scroll.scroller

const slide = keyframes`
    from {
        opacity: 0;
        transform: translateX(20%);
    }

    to {
        opacity: 1;
        transform: translateX(0%);
    }
`

const Container = styled.header`
  position: absolute;
  height: 50px;
  width: auto;
  top: 70px;
  right: 0;
  z-index: 19;
  /* padding: 0.3rem; */
  box-shadow: 0px 2px 8px ${props => props.theme.gray4};
  background-color: ${props => props.theme.white1};
  border-radius: 30px 0 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.desktop} {
    display: flex;
    height: auto;
    width: auto;
  }
`

const ContainerLogin = styled.header`
  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.desktop} {
    display: flex;
    height: auto;
    width: auto;
  }
`
const ContainerMobile = styled.div`
  position: fixed;
  height: 100%;
  width: 35%;
  top: 0;
  right: 0;
  /* padding: 0.3rem 0.5rem; */
  box-shadow: 0.1px 0 0 0.1px ${props => props.theme.gray3};
  background-color: ${props => props.theme.white1};
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 0;
  opacity: 0.9;
  z-index: 9;

  @media ${devices.phoneOnly} {
    width: 60%;
    display: flex;
  }

  @media ${devices.tabletPortrait} {
    display: flex;
  }

  @media ${devices.desktop} {
    display: none;
  }
`

const LinkOne = styled.a<{ Color?: boolean; textDecoration?: boolean }>`
  height: 100%;
  color: ${props =>
    props.Color ? props.theme.color_text2 : props.theme.color_text1};
  text-decoration: ${props => (props.textDecoration ? 'underline' : 'none')};
  padding: 0.8rem;
  cursor: pointer;
  font-size: 1.2rem;

  @media ${devices.tabletLandscape} {
    font-size: 1rem;
    padding: 0.8rem 0.5rem;
  }

  @media ${devices.desktop} {
    font-size: 1.1rem;
    padding: 0.8rem 0.5rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 1.2rem;
    padding: 0.8rem;
  }
`

const ButtonMobile = styled.button`
  padding: 0.5rem;
  margin: 0.1rem;
  border: none;
  background: transparent;
  text-align: start;
  border-bottom: 0.1rem solid ${props => props.theme.color_primary_main};
  width: 100%;
  color: ${props => props.theme.color_text2};

  & a:hover {
    text-decoration: underline;
  }
`

const MenuButton = styled.button`
  border: none;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;

  & svg {
    width: 30px;
    height: 30px;
    fill: transparent;
  }
`

const ButtonOnBorder = styled.button<{
  backgroundColor?: boolean
  isPadding?: boolean
  isNoBorder?: boolean
  typeBg?: string
}>`
  height: 100%;
  border: none;
  border-left: 0.1rem solid
    ${props => (props.isNoBorder ? 'transparent' : props.theme.gray4)};
  position: relative;
  background-color: ${props =>
    props.backgroundColor
      ? props.typeBg
        ? props.theme.button_yellow_link
        : props.theme.color_primary_main
      : 'transparent'};
  padding: 0.8rem 0;
  padding-right: ${props => (props.isPadding ? '100px' : '0')};

  & a:hover {
    text-decoration: underline;
  }

  @media ${devices.desktop} {
    padding-right: ${props => (props.isPadding ? '40px' : '0')};
  }

  @media ${devices.bigDesktop} {
    padding-right: ${props => (props.isPadding ? '100px' : '0')};
  }
`

const Effect = styled.div<{ delay: string }>`
  animation: ${slide} 1s 1 both ${props => props.delay};
`

const SubButton = styled.button`
  border: none;
  background: transparent;
  padding: 0.5rem 1rem;
  text-align: center;
  border-bottom: 0.1rem solid ${props => props.theme.gray4};
  width: 100%;
`

const SubLink = styled.button`
  color: ${props => props.theme.color_text2};
  font-weight: bold;
  text-decoration: none;
  font-size: 0.9rem;
  background: transparent;
  border: none;
  cursor: pointer;

  @media ${devices.phoneOnly} {
    color: ${props => props.theme.color_text1};
  }

  @media ${devices.tabletPortrait} {
    color: ${props => props.theme.color_text1};
  }

  @media ${devices.desktop} {
    color: ${props => props.theme.color_text2};
    font-size: 0.8rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 0.9rem;
  }
`

const Ul = styled.ul``

const Li = styled.li<{ Lista?: boolean }>`
  text-align: start;
  padding: 0.5rem 0.5rem;
  margin: 0.1rem;

  @media ${devices.phoneOnly} {
    display: ${props => (props.Lista ? 'block' : 'none')};
  }

  @media ${devices.tabletPortrait} {
    display: ${props => (props.Lista ? 'block' : 'none')};
  }

  @media ${devices.desktop} {
    display: none;
  }
`

interface Props {
  isMenuVisible: boolean
  handleSetMenu: () => void
  isHeaderVisible: boolean
  handleSetHeader: () => void
  segmentName: string
  showHeader: boolean
  segments: Segments[]
  segmentId: string
  selectedSegment: (value: string) => void
  selectedSegmentName: (value: string) => void
  fetchSegment: () => void
}

const Header = ({
  isMenuVisible,
  handleSetMenu,
  isHeaderVisible,
  handleSetHeader,
  showHeader,
  segments,
  segmentId,
  selectedSegment,
  fetchSegment,
  selectedSegmentName
}: Props): JSX.Element | null => {
  const history = useHistory()
  const location = useLocation()

  const addScroll = (section: string) => {
    localStorage.setItem('scroller', section)
  }

  useEffect(() => {
    fetchSegment()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (showHeader) {
    return (
      <>
        <ContainerMobile>
          <Effect delay='1ms'>
            <MenuButton>
              <IconMenu />
            </MenuButton>

            {/*<Catalog.Button3
              variant='primary'
              disabled={false}
              onClick={() =>
                window.open('https://www.trbmed.com.br/', '_blank')
              }
              size='sm'
            >
              Portal Médico
            </Catalog.Button3>*/}

            <ButtonMobile>
              <LinkOne
                onClick={() => {
                  scroller.scrollTo('1', {
                    duration: 1500,
                    delay: 100,
                    smooth: true
                  })
                  handleSetHeader()
                  history.push('/')
                }}
              >
                Inicio
              </LinkOne>
            </ButtonMobile>
            <ButtonMobile>
              {location.pathname === '/' ? (
                <LinkOne
                  onClick={() => {
                    scroller.scrollTo('2', {
                      duration: 1500,
                      delay: 100,
                      smooth: true,
                      offset: -40
                    })
                  }}
                >
                  Sobre a TRB
                </LinkOne>
              ) : (
                <LinkOne
                  onClick={() => {
                    history.push('/')
                    addScroll('2')
                  }}
                >
                  Sobre a TRB
                </LinkOne>
              )}
            </ButtonMobile>
            <ButtonMobile>
              <Ul>
                <LinkOne onClick={() => handleSetMenu()}>Produtos</LinkOne>
                {segments.map(segment => {
                  return (
                    <Li
                      title={segment.name}
                      Lista={isMenuVisible}
                      key={segment.id}
                    >
                      <SubLink
                        onClick={() => {
                          selectedSegment(String(segment.id))
                          selectedSegmentName(segment.name)
                          history.push(
                            `/product/${segment.id}/${segment.name
                              .split(' ')
                              .join('-')
                              .toLowerCase()}`
                          )
                        }}
                      >
                        {segment.name}
                      </SubLink>
                    </Li>
                  )
                })}
              </Ul>
            </ButtonMobile>

            {
              // <ButtonMobile style={{ background: '#fff200' }}>
              //   <LinkOne onClick={() => history.push('/comprar')}>
              //     Comprar
              //   </LinkOne>
              // </ButtonMobile>
            }

            <ButtonMobile>
              <LinkOne
                onClick={() => {
                  history.push('/pharmacovigilance')
                }}
              >
                Farmacovigilância
              </LinkOne>
            </ButtonMobile>

            <ButtonMobile>
              {location.pathname === '/' ? (
                <LinkOne
                  onClick={() => {
                    scroller.scrollTo('5', {
                      duration: 1500,
                      delay: 100,
                      smooth: true,
                      offset: -40
                    })
                    handleSetHeader()
                  }}
                >
                  Notícias
                </LinkOne>
              ) : (
                <LinkOne
                  onClick={() => {
                    history.push('/')
                    addScroll('5')
                  }}
                >
                  Notícias
                </LinkOne>
              )}
            </ButtonMobile>
            <ButtonMobile>
              {location.pathname !== '/pharmacovigilance' ? (
                <LinkOne
                  onClick={() =>
                    scroller.scrollTo('6', {
                      duration: 1500,
                      delay: 100,
                      smooth: true
                    })
                  }
                >
                  Fale Conosco
                </LinkOne>
              ) : (
                <LinkOne
                  onClick={() => {
                    history.push('/')
                    addScroll('6')
                  }}
                >
                  Fale Conosco
                </LinkOne>
              )}
            </ButtonMobile>
            <ButtonMobile>
              <LinkOne
                onClick={() =>
                  window.open('https://www.trbmed.com.br/', '_blank')
                }
              >
                Portal Médico
              </LinkOne>
              )
            </ButtonMobile>
          </Effect>
        </ContainerMobile>
      </>
    )
  } else {
    return (
      <>
        <ContainerLogin>
          <Catalog.Button2
            variant='primary'
            disabled={false}
            onClick={() => window.open('https://www.trbmed.com.br/', '_blank')}
            size='sm'
          >
            Portal Médico
          </Catalog.Button2>
        </ContainerLogin>
        <Container>
          {location.pathname !== '/' ? (
            <ButtonOnBorder isNoBorder>
              <LinkOne
                onClick={() => {
                  history.push('/')
                  addScroll('1')
                }}
              >
                Inicio
              </LinkOne>
            </ButtonOnBorder>
          ) : null}
          <ButtonOnBorder isNoBorder={location.pathname === '/'}>
            {location.pathname === '/' ? (
              <LinkOne
                onClick={() => {
                  scroller.scrollTo('2', {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -40
                  })
                }}
              >
                Sobre a TRB
              </LinkOne>
            ) : (
              <LinkOne
                onClick={() => {
                  history.push('/')
                  addScroll('2')
                }}
              >
                Sobre a TRB
              </LinkOne>
            )}
          </ButtonOnBorder>
          <ButtonOnBorder
            backgroundColor={isMenuVisible}
            onClick={() => handleSetMenu()}
          >
            <LinkOne Color={isMenuVisible} textDecoration={isMenuVisible}>
              Produtos
            </LinkOne>
            <SubMenu showMenu={isMenuVisible} handleSetMenu={handleSetMenu}>
              {segments.map(segment => {
                return (
                  <SubButton
                    title={segment.name}
                    key={`${segment.id}-${segment.name}`}
                  >
                    <Effect delay='100ms'>
                      <SubLink
                        onClick={() => {
                          selectedSegment(String(segment.id))
                          selectedSegmentName(segment.name)
                          history.push(
                            `/product/${segment.id}/${segment.name
                              .split(' ')
                              .join('-')
                              .toLowerCase()}`
                          )
                        }}
                      >
                        {segment.name}
                      </SubLink>
                    </Effect>
                  </SubButton>
                )
              })}
            </SubMenu>
          </ButtonOnBorder>

          {
            // <ButtonOnBorder backgroundColor typeBg='button_yellow_link'>
            //  <LinkOne onClick={() => history.push('/comprar')}>Comprar</LinkOne>
            // </ButtonOnBorder>
          }

          <ButtonOnBorder>
            {location.pathname === '/' ? (
              <LinkOne
                onClick={() => {
                  scroller.scrollTo('5', {
                    duration: 1500,
                    delay: 100,
                    smooth: true,
                    offset: -40
                  })
                }}
              >
                Notícias
              </LinkOne>
            ) : (
              <LinkOne
                onClick={() => {
                  history.push('/')
                  addScroll('5')
                }}
              >
                Notícias
              </LinkOne>
            )}
          </ButtonOnBorder>
          <ButtonOnBorder>
            <LinkOne onClick={() => history.push('/pharmacovigilance')}>
              Farmacovigilância
            </LinkOne>
          </ButtonOnBorder>
          <ButtonOnBorder isPadding>
            {location.pathname !== '/pharmacovigilance' ? (
              <LinkOne
                onClick={() =>
                  scroller.scrollTo('6', {
                    duration: 1500,
                    delay: 100,
                    smooth: true
                  })
                }
              >
                Fale Conosco
              </LinkOne>
            ) : (
              <LinkOne
                onClick={() => {
                  history.push('/')
                  addScroll('6')
                }}
              >
                Fale Conosco
              </LinkOne>
            )}
          </ButtonOnBorder>
        </Container>
      </>
    )
  }
}
export default Header
