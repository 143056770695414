import axios from "axios";

export const watsonThalita = process.env.REACT_APP_WATSON_API
  ? process.env.REACT_APP_WATSON_API
  : "http://localhost:3002/";

export const axiosWebsite = axios.create({
  baseURL: process.env.REACT_APP_WEBSITE_API
    ? process.env.REACT_APP_WEBSITE_API
    : "https://trb-pharma-website-develop.digitaly.tech/api",
});

export const axiosCep = axios.create({
  baseURL: process.env.REACT_APP_VIA_CEP
    ? process.env.REACT_APP_VIA_CEP
    : "https://viacep.com.br/ws/",
});

export const axiosEmail = axios.create({
  baseURL: process.env.REACT_APP_MAIL_API
    ? process.env.REACT_APP_MAIL_API
    : "https://trb-pharma-mail-develop.digitaly.tech/api",
});

export const sendMail = process.env.REACT_APP_SEND_MAIL
  ? process.env.REACT_APP_SEND_MAIL
  : "gilberto@digitaly.tech";

const defaultOptions = {
  baseURL: "https://locatestore.intellibrand.ai/products",
  headers: {
    Authorization:
      "JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjp7ImlkQnJhbmQiOjE5NiwiaWRDb21wYW55Ijo1NDcyLCJ1c2VyTmFtZSI6IlRSQiBQaGFybWEiLCJ1c2VHZW9Mb2NhdGlvbiI6ZmFsc2V9fQ.5OOOuoO617EKMojcGnfJNEgBJv2naspHpese4B9A-6A",
  },
};

export const axiosLocatestore = axios.create(defaultOptions);
