import type { AxiosError, AxiosResponse } from "axios";
import { axiosWebsite } from "helpers/VariableAmbient";

export const fetchProducts = async (id: string) => {
  return await axiosWebsite
    .get(`/product-site?segment_id=${id}`)
    .then((response: AxiosResponse) => {
      return response.data.data;
    })
    .catch((error: AxiosError) => {
      return {
        message: `Error: ${error.response?.status}`,
        type: "ERROR",
        status: error.response?.status,
      };
    });
};
