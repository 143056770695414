import { openPdf } from "components/Product/ProductFolder/helpers";

import { isUnauthorized } from "helpers/errors";
import { axiosWebsite } from "helpers/VariableAmbient";
import { setGlobalMessage, toggleGlobalLoading } from "redux/actions";

import type { DivButtonsProps } from "./DivButtons.d";
import { Product } from "./DivButtons.styles";

const DivButtons = ({
  id,
  drugleaflet_label_1,
  drugleaflet_label_2,
  drugleaflet_label_3,
  dispatch,
  history,
}: DivButtonsProps) => {
  const openDrugleaflet = async (product: number, drugleaflet: number) => {
    try {
      dispatch(toggleGlobalLoading());

      const { data } = await axiosWebsite.get(
        `/product-drugleaflet?id=${product}&drugleaflet=${drugleaflet}`
      );

      openPdf(data.data[0].drugleaflet);
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao abrir a bula: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(toggleGlobalLoading());
    }
  };

  return (
    <Product.DivButtons delay="600ms">
      {drugleaflet_label_1 !== "" &&
      drugleaflet_label_1 !== null &&
      drugleaflet_label_1 !== undefined ? (
        <Product.Button
          title={drugleaflet_label_1}
          onClick={() => openDrugleaflet(id, 1)}
        >
          {drugleaflet_label_1 ? drugleaflet_label_1 : "Bula"}
        </Product.Button>
      ) : null}
      {drugleaflet_label_2 !== "" &&
      drugleaflet_label_2 !== null &&
      drugleaflet_label_2 !== undefined ? (
        <Product.Button
          title={drugleaflet_label_2}
          onClick={() => openDrugleaflet(id, 2)}
        >
          {drugleaflet_label_2 ? drugleaflet_label_2 : "Bula"}
        </Product.Button>
      ) : null}
      {drugleaflet_label_3 !== "" &&
      drugleaflet_label_3 !== null &&
      drugleaflet_label_3 !== undefined ? (
        <Product.Button
          title={drugleaflet_label_3}
          onClick={() => openDrugleaflet(id, 3)}
        >
          {drugleaflet_label_3 ? drugleaflet_label_3 : "Bula"}
        </Product.Button>
      ) : null}
    </Product.DivButtons>
  );
};

export default DivButtons;
