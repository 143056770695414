import { GlobalState, AllowedActions } from "./types";

// -- State Inicial --
const initialState: GlobalState = {
  globalMessage: undefined,
  globalLoading: false,
  successMessage: undefined,
  userId: undefined,
  user: undefined,
  catalog: false,
  catalogList: [],
};

// -- atribuindo switch --
export function globalReducer(state = initialState, action: AllowedActions) {
  switch (action.type) {
    case "GLOBAL_MESSAGE/SET":
      return {
        ...state,
        globalMessage: action.payload,
      };
    case "GLOBAL_MESSAGE/CLEAR":
      return {
        ...state,
        globalMessage: undefined,
      };
    case "GLOBAL_LOADING/TOGGLE":
      return {
        ...state,
        globalLoading: !state.globalLoading,
      };
    case "SUCCESS_MESSAGE/SET":
      return {
        ...state,
        successMessage: action.payload,
      };
    case "SUCCESS_MESSAGE/CLEAR":
      return {
        ...state,
        successMessage: undefined,
      };
    case "USER_ID/SET":
      return {
        ...state,
        userId: action.payload,
      };
    case "USER_ID/CLEAR":
      return {
        ...state,
        userId: undefined,
      };
    case "USER/SET":
      return {
        ...state,
        user: action.payload,
      };
    case "USER/CLEAR":
      return {
        ...state,
        userProfile: undefined,
      };
    case "CATALOG/TOGGLE":
      return {
        ...state,
        catalog: action.payload,
      };
    case "CATALOG/SET":
      return {
        ...state,
        catalogList: action.payload,
      };
    default:
      return state;
  }
}
