import React from "react";
import styled from "styled-components";
import { devices } from "helpers/devices";

const Container = styled.div<{ padding: string }>`
  position: absolute;
  top: 40px;
  left: -1px;
  width: auto;
  min-width: 165px;
  height: auto;
  padding: ${props => props.padding};
  box-shadow: 1px 2px 4px ${props => props.theme.gray4};
  background-color: ${props => props.theme.color_primary_main};
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  z-index: 20;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: flex;
  }
`;

interface Props {
  //SubMenu
  padding?: string;
  showMenu: boolean;
  children?: React.ReactNode;
  handleSetMenu: () => void;
}

const SubMenu = ({
  showMenu,
  children,
  handleSetMenu,
  padding = "1rem",
}: Props): JSX.Element | null => {
  if (showMenu) {
    return (
      <Container padding={padding} onMouseLeave={() => handleSetMenu()}>
        {children}
      </Container>
    );
  } else {
    return null;
  }
};
export default SubMenu;
