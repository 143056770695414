import Header from "components/Home/Header/Header";
import { Segments } from "interfaces/Segment";
import { useState } from "react";
import styled, { keyframes } from "styled-components";

import LogoHeader from "assets/LogoHeader.png";
import { devices } from "helpers/devices";
import ImageGirl from "assets/bg-girls.png";
import {
  InputCelAndPhone,
  InputCEP,
  InputDate,
  InputDateMonth,
  InputEmail,
  InputName,
  InputNumber,
  InputNumberDecimal,
  InputRadio,
  InputSelect,
  InputSelectRow,
  InputText,
  InputTextArea,
  InputTextRow,
  InputUFSelect,
} from "components/Generic/GenericInputs";
import GenericButton from "components/Generic/GenericButton";
import {
  FormPharmacovigilance,
  InitFormPharmacovigilance,
} from "interfaces/Pharmacovigilance";
import { useDispatch } from "react-redux";
import {
  setGlobalMessage,
  setSuccessMessage,
  toggleGlobalLoading,
} from "redux/actions";
import { isUnauthorized } from "helpers/errors";
import { useHistory } from "react-router-dom";
import { axiosEmail } from "helpers/VariableAmbient";
import { useEffect } from "react";
import {
  formatDate,
  formatDateMonth,
  unFormatDate,
  unFormatDateMonth,
} from "helpers/formats";
import { validDate } from "helpers/validsField";
import { consumptionRouteValue } from "helpers/routeAdministration";

const Fade = keyframes`
    0% {
        transform: scaleX(.3);
        opacity: 0;
    }
    70% {
        transform: scaleX(1.1);
        opacity: 1;
    }
    100% {
        transform: scaleX(1);
    }
`;

const Container = styled.section`
  width: 100%;
  height: 100%;
  min-height: 800px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 8rem;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${devices.phoneOnly} {
    padding: 0 2rem;
  }

  @media ${devices.tabletPortrait} {
    padding: 0 2rem;
  }

  @media ${devices.tabletLandscape} {
    padding: 0 4rem;
  }

  @media ${devices.desktop} {
    padding: 0 8rem;
  }
`;

const Logo = styled.img`
  width: 30%;
  height: auto;
  position: absolute;
  top: 30px;
  left: 60px;
  z-index: 5;

  @media ${devices.phoneOnly} {
    top: 0px;
    left: 5px;
    width: 100%;
    max-width: 200px;
  }

  @media ${devices.tabletPortrait} {
    top: 10px;
    left: 20px;
    width: 100%;
    max-width: 350px;
  }

  @media ${devices.tabletLandscape} {
    top: 30px;
    left: 50px;
    width: 40%;
    max-width: 350px;
  }

  @media ${devices.desktop} {
    top: 30px;
    left: 60px;
    width: 40%;
    max-width: 450px;
  }

  @media ${devices.bigDesktop} {
    width: 30%;
    max-width: 550px;
  }
`;

const TextAndImg = styled.div`
  padding-top: 300px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media ${devices.phoneOnly} {
    padding-top: 110px;
  }

  @media ${devices.tabletPortrait} {
    padding-top: 200px;
  }

  @media ${devices.desktop} {
    padding-top: 230px;
  }

  @media ${devices.bigDesktop} {
    padding-top: 300px;
  }
`;

const InputsDiv = styled.form`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 100px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 700px;
  height: auto;

  @media ${devices.phoneOnly} {
    display: none;
  }

  @media ${devices.tabletPortrait} {
    display: none;
  }

  @media ${devices.tabletLandscape} {
    display: block;
    max-width: 300px;
  }

  @media ${devices.desktop} {
    max-width: 500px;
  }

  @media ${devices.bigDesktop} {
    max-width: 700px;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: ${props => props.theme.color_primary_main};
  margin: 1rem 0;

  @media ${devices.phoneOnly} {
    font-size: 18px;
  }

  @media ${devices.tabletPortrait} {
    font-size: 22px;
  }

  @media ${devices.bigDesktop} {
    font-size: 26px;
  }
`;

const Text = styled.p<{ maxWidth?: string }>`
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: ${props => props.theme.gray1};
  margin: 1rem 0;
  max-width: ${props => (props.maxWidth ? props.maxWidth : null)};
  padding: 0.5rem;

  @media ${devices.phoneOnly} {
    font-size: 12px;
    padding: 0.3rem;
  }

  @media ${devices.tabletPortrait} {
    font-size: 16px;
    padding: 0.4rem;
  }

  @media ${devices.bigDesktop} {
    font-size: 20px;
    padding: 0.5rem;
  }
`;

const TieBothText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & p {
    width: 50%;
  }
`;

const ItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0.3rem 0;

  @media ${devices.phoneOnly} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${devices.tabletPortrait} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${devices.tabletLandscape} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${devices.desktop} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const RowAddButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0.3rem 0;
  padding: 0.5rem 1rem;

  & button {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    color: ${props => props.theme.color_primary_main};
    text-decoration: underline;
    margin-right: 1rem;

    &:hover {
      color: ${props => props.theme.color_primary_light};
    }
  }
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${props => props.theme.white3};
  box-shadow: 0px 0px 2px ${props => props.theme.gray4};
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin: 0.5rem;
  cursor: pointer;
`;

const Checked = styled.div`
  animation: ${Fade} 1 200ms ease-out;
  background: ${props => props.theme.button_normal};
  width: 12px;
  height: 12px;
  border-radius: 3px;
`;

const CheckText = styled.label`
  font-size: 0.9rem;
  color: ${props => props.theme.gray2};
  padding: 0.5rem;
`;

const MessageCheck = styled.label`
  /* border: ${props => props.theme.white4} 1px solid;
  border-radius: 5px; */
  font-size: 0.9rem;
  color: ${props => props.theme.gray3};
  padding: 0.5rem;
  width: 100%;
`;

const Button = styled(GenericButton)<{ isEnabled?: boolean }>`
  margin-top: 2rem;
  background-color: ${props =>
    props.isEnabled ? props.theme.button_normal : props.theme.gray4};
  color: ${props => (props.isEnabled ? props.theme.white1 : props.theme.gray3)};
  cursor: ${props => (props.isEnabled ? "pointer" : "not-allowed")};

  &:hover {
    background-color: ${props =>
      props.isEnabled ? props.theme.button_normal_hover : props.theme.white5};
  }
`;

interface Props {
  isHeaderVisible: boolean;
  handleSetHeader: () => void;
  segmentName: string;
  segmentId: string;
  selectedSegment: (value: string) => void;
  selectedSegmentName: (value: string) => void;
  fetchSegment: () => void;
  segments: Segments[];
}

interface medicines {
  medicinesOne: boolean;
  medicinesTwo: boolean;
  medicinesThree: boolean;
  medicinesFour: boolean;
  medicinesFive: boolean;
}

const PharmacovigilanceRender = ({
  isHeaderVisible,
  handleSetHeader,
  segmentId,
  segmentName,
  selectedSegment,
  selectedSegmentName,
  fetchSegment,
  segments,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const handleSetMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };
  const [data, setData] = useState<FormPharmacovigilance>(
    InitFormPharmacovigilance
  );

  const [otherMedicines, setOtherMedicines] = useState<medicines>({
    medicinesOne: false,
    medicinesTwo: false,
    medicinesThree: false,
    medicinesFour: false,
    medicinesFive: false,
  });

  const handleSubmit = async () => {
    try {
      dispatch(toggleGlobalLoading());

      await axiosEmail.post(`/farmacovigilancia`, data);

      dispatch(setSuccessMessage("Dados enviados com sucesso!"));

      history.push("/");
    } catch (error: any) {
      if (isUnauthorized(error)) {
        history.push("/");
      } else {
        dispatch(
          setGlobalMessage({
            message: `Ocorreu um erro ao enviar os dados do usuário: ${error}`,
            type: "ERROR",
          })
        );
      }
    } finally {
      dispatch(toggleGlobalLoading());
    }
  };

  useEffect(() => {
    if (
      validDate(unFormatDate(data.endOfTreatment!)) &&
      validDate(unFormatDate(data.startOfTreatment!))
    ) {
      // console.log("Dentro");
      if (
        new Date(unFormatDate(data.startOfTreatment!)) >
        new Date(unFormatDate(data.endOfTreatment!))
      ) {
        setData({
          ...data,
          startOfTreatment: "",
          endOfTreatment: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endReaction!)) &&
      validDate(unFormatDate(data.startReaction!))
    ) {
      if (
        new Date(unFormatDate(data.startReaction!)) >
        new Date(unFormatDate(data.endReaction!))
      ) {
        setData({
          ...data,
          startReaction: "",
          endReaction: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endDateOne!)) &&
      validDate(unFormatDate(data.startDateOne!))
    ) {
      if (
        new Date(unFormatDate(data.startDateOne!)) >
        new Date(unFormatDate(data.endDateOne!))
      ) {
        setData({
          ...data,
          startDateOne: "",
          endDateOne: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endDateTwo!)) &&
      validDate(unFormatDate(data.startDateTwo!))
    ) {
      if (
        new Date(unFormatDate(data.startDateTwo!)) >
        new Date(unFormatDate(data.endDateTwo!))
      ) {
        setData({
          ...data,
          startDateTwo: "",
          endDateTwo: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endDateThree!)) &&
      validDate(unFormatDate(data.startDateThree!))
    ) {
      if (
        new Date(unFormatDate(data.startDateThree!)) >
        new Date(unFormatDate(data.endDateThree!))
      ) {
        setData({
          ...data,
          startDateThree: "",
          endDateThree: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endDateFour!)) &&
      validDate(unFormatDate(data.startDateFour!))
    ) {
      if (
        new Date(unFormatDate(data.startDateFour!)) >
        new Date(unFormatDate(data.endDateFour!))
      ) {
        setData({
          ...data,
          startDateFour: "",
          endDateFour: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    if (
      validDate(unFormatDate(data.endDateFive!)) &&
      validDate(unFormatDate(data.startDateFive!))
    ) {
      if (
        new Date(unFormatDate(data.startDateFive!)) >
        new Date(unFormatDate(data.endDateFive!))
      ) {
        setData({
          ...data,
          startDateFive: "",
          endDateFive: "",
        });
        dispatch(
          setGlobalMessage({
            message: `A data de início não deve ser maior do que a data de término!`,
            type: "WARN",
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (data.productName === "ARTRODAR") {
      // a fazer ....
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.productName]);

  useEffect(() => {
    if (data.initialsTheSame === "Sim") {
      setData({
        ...data,
        initials: data.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.initialsTheSame]);

  // useEffect(() => {
  //   console.log(data);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data]);

  return (
    <>
      <Header
        showHeader={isHeaderVisible}
        isMenuVisible={isMenuVisible}
        handleSetMenu={handleSetMenu}
        isHeaderVisible={isHeaderVisible}
        handleSetHeader={handleSetHeader}
        segments={segments}
        segmentId={segmentId}
        segmentName={segmentName}
        selectedSegment={selectedSegment}
        selectedSegmentName={selectedSegmentName}
        fetchSegment={fetchSegment}
      />
      <Logo src={LogoHeader} alt="Logo TRB Pharma" />
      <Container>
        <TextAndImg>
          <TextDiv>
            <Title>O que é Farmacovigilância?</Title>
            <Text>
              A farmacovigilância consiste na ciência e atividades relativas à
              detecção, avaliação, compreensão e prevenção de eventos adversos
              ou quaisquer outros problemas envolvendo medicamentos.
              <br />
              <br />
              Dentre seus objetivos, destacam-se: contribuir com o uso seguro e
              racional de medicamentos, monitorar a ocorrência de eventos
              adversos, zelar pelo cuidado e pela segurança dos pacientes no que
              diz respeito ao uso de medicamentos, promover a compreensão e a
              capacitação em farmacovigilância e contribuir para a avaliação
              contínua dos benefícios relacionados ao uso desses produtos, de
              forma que os benefícios superem os riscos.
            </Text>
            <Title>O que é um evento adverso?</Title>
            <Text>
              Trata-se de qualquer evento médico indesejável que tenha ocorrido
              com o paciente durante o uso do medicamento, sem que haja
              necessariamente relação causal com o tratamento. Pode ser qualquer
              sintoma, doença ou sinal desfavorável e não intencional
              temporalmente associado ao uso do medicamento.
            </Text>
            <Title>Alguns exemplos de eventos adversos:</Title>
            <TieBothText>
              <Text maxWidth="400px">
                · Suspeita de reação adversa;
                <br />
                <br />· Interações medicamentosas;
                <br />
                <br />· Suspeita de inefetividade terapêutica;
                <br />
                <br />· Erros de medicação;
                <br />
                <br />· Usos diferente do preconizado em bula;
                <br />
                <br />· Intoxicações;
              </Text>
              <Text maxWidth="400px">
                · Abuso e overdose de medicamentos;
                <br />
                <br />· Exposição acidental;
                <br />
                <br />· Exposição ocupacional;
                <br />
                <br />· Exposição durante a lactação ou gestação;
                <br />
                <br />· Reações relacionadas a medicamentos com suspeita de
                desvio de qualidade.
              </Text>
            </TieBothText>
            <Text>
              Uma farmacovigilância forte e eficiente só pode ser construída com
              a participação de todos. Contamos com a sua colaboração para
              recebermos informações adequadas sobre possíveis eventos adversos.
            </Text>
          </TextDiv>
          <Image src={ImageGirl} alt="" />
        </TextAndImg>
        <InputsDiv
          onSubmit={event => {
            event.preventDefault();
            handleSubmit();
          }}
          autoComplete="none"
        >
          <br />
          <br />
          <br />
          <Title>Dados da pessoa que está relatando a reação adversa:</Title>
          <br />
          <ItemRow>
            <InputName
              label="Nome *"
              maxWidth="350px"
              value={data.name}
              changeValue={value =>
                setData({
                  ...data,
                  name: value,
                })
              }
              required
            />
            <InputEmail
              label="E-mail *"
              maxWidth="350px"
              value={data.email}
              changeValue={value =>
                setData({
                  ...data,
                  email: value,
                })
              }
              required
            />
            <InputCelAndPhone
              label="Telefone para contato *"
              maxWidth="250px"
              value={data.contactPhone}
              changeValue={value =>
                setData({
                  ...data,
                  contactPhone: value,
                })
              }
              required
            />
          </ItemRow>
          <ItemRow>
            <InputText
              label="Profissão *"
              maxWidth="350px"
              value={data.profession}
              changeValue={value =>
                setData({
                  ...data,
                  profession: value,
                })
              }
              required
            />
            <InputCEP
              label="CEP *"
              maxWidth="250px"
              value={data.zipcode}
              changeValue={value =>
                setData({
                  ...data,
                  zipcode: value,
                })
              }
              changeOtherValue={value =>
                setData({
                  ...data,
                  state: value.uf,
                  city: value.localidade,
                })
              }
              required
            />
            <InputUFSelect
              label="UF *"
              value={data.state}
              changeValue={value =>
                setData({
                  ...data,
                  state: value,
                })
              }
              required
            />
            <InputText
              label="Cidade *"
              maxWidth="250px"
              value={data.city}
              changeValue={value =>
                setData({
                  ...data,
                  city: value,
                })
              }
              required
            />
          </ItemRow>
          <br />
          <Title>Identificação e dados do paciente:</Title>
          <br />
          <ItemRow>
            <InputRadio
              name="initialsTheSame"
              label="O paciente é a mesma pessoa que está fazendo relato? *"
              value={data.initialsTheSame}
              changeValue={value =>
                setData({
                  ...data,
                  initialsTheSame: value,
                })
              }
              required
            />
          </ItemRow>
          {data.initialsTheSame !== "Sim" ? (
            <ItemRow>
              <InputName
                label="Iniciais ou nome *"
                maxWidth="350px"
                value={data.initials}
                changeValue={value =>
                  setData({
                    ...data,
                    initials: value,
                  })
                }
                required
              />
            </ItemRow>
          ) : null}
          <ItemRow>
            <InputSelect
              label="Sexo"
              maxWidth="250px"
              value={data.gender}
              changeValue={value =>
                setData({
                  ...data,
                  gender: value,
                })
              }
            >
              <option selected disabled value="">
                {""}
              </option>
              <option value="Masculino">Masculino</option>
              <option value="Feminino">Feminino</option>
            </InputSelect>
            <InputNumberDecimal
              label="Peso (Kg)"
              maxWidth="100px"
              value={data.weight}
              changeValue={value =>
                setData({
                  ...data,
                  weight: value,
                })
              }
            />
            <InputNumber
              label="Idade"
              maxWidth="100px"
              value={data.age}
              changeValue={value =>
                setData({
                  ...data,
                  age: value,
                })
              }
            />
            <InputNumberDecimal
              label="Altura (M)"
              maxWidth="100px"
              value={data.height}
              changeValue={value =>
                setData({
                  ...data,
                  height: value,
                })
              }
            />
          </ItemRow>
          <br />
          <Title>Medicamentos suspeitos:</Title>
          <br />
          <ItemRow>
            <InputSelect
              label="Nome Comercial: *"
              maxWidth="350px"
              value={data.productName}
              changeValue={value =>
                setData({
                  ...data,
                  productName: value,
                })
              }
              required
            >
              <option selected disabled value="">
                {""}
              </option>
              {consumptionRouteValue.map(consumption => {
                return (
                  <option value={consumption.productName}>
                    {consumption.productName}
                  </option>
                );
              })}
            </InputSelect>
            <InputText
              label="N° do Lote"
              maxWidth="350px"
              value={data.lotNumber}
              changeValue={value =>
                setData({
                  ...data,
                  lotNumber: value,
                })
              }
            />
            <InputDateMonth
              label="Data de Validade"
              maxWidth="250px"
              typeInput="month"
              value={
                data.expirationDate
                  ? unFormatDateMonth(data.expirationDate)
                  : data.expirationDate
              }
              changeValue={value =>
                setData({
                  ...data,
                  expirationDate: formatDateMonth(value),
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputTextRow
              label="Dose administrada/tomada (ex: 1 cápsula, 1 injeção, etc) *"
              maxWidth="550px"
              isRow
              value={data.dosage}
              changeValue={value =>
                setData({
                  ...data,
                  dosage: value,
                })
              }
              required
            />
            <InputSelectRow
              label="Frequência de uso (ex: diário, semanal, etc.) *"
              maxWidth="550px"
              isRow
              value={data.frequency}
              changeValue={value =>
                setData({
                  ...data,
                  frequency: value,
                })
              }
              required
            >
              <option value="" disabled selected>
                Selecione
              </option>
              <option value="Diário">Diário</option>
              <option value="Semanal">Semanal</option>
              <option value="Mensal">Mensal</option>
              <option value="Quinzenal">Quinzenal</option>
              <option value="Outros">Outros</option>
            </InputSelectRow>
          </ItemRow>
          <ItemRow>
            <InputSelect
              label="Via de administração *"
              maxWidth="350px"
              value={data.consumption}
              changeValue={value =>
                setData({
                  ...data,
                  consumption: value,
                })
              }
              required
            >
              <option value="" disabled selected>
                {" "}
              </option>
              {consumptionRouteValue
                .filter(
                  consumption => consumption.productName === data.productName
                )
                .map(consumption => {
                  return consumption.consumption.map(obj => {
                    return <option value={obj.name}>{obj.name}</option>;
                  });
                })}
              <option value="Outros">Outros</option>
            </InputSelect>
            <InputText
              label="Indicação (motivo pelo qual a medicação está / estava sendo tomada) *"
              maxWidth="700px"
              value={data.recommendation}
              changeValue={value =>
                setData({
                  ...data,
                  recommendation: value,
                })
              }
              required
            />
          </ItemRow>
          <ItemRow>
            <InputDate
              label="Início do tratamento *"
              maxWidth="250px"
              value={
                data.startOfTreatment
                  ? unFormatDate(data.startOfTreatment)
                  : data.startOfTreatment
              }
              changeValue={value =>
                setData({
                  ...data,
                  startOfTreatment: formatDate(value),
                })
              }
              required
            />
            {data.endOfTreatmentTheSame === "Não" ? (
              <InputDate
                label="Término do tratamento *"
                maxWidth="250px"
                value={
                  data.endOfTreatment
                    ? unFormatDate(data.endOfTreatment)
                    : data.endOfTreatment
                }
                changeValue={value =>
                  setData({
                    ...data,
                    endOfTreatment: formatDate(value),
                  })
                }
                required
              />
            ) : null}
            <InputRadio
              name="endOfTreatmentTheSame"
              label="Em Uso? *"
              maxWidth="150px"
              value={data.endOfTreatmentTheSame}
              changeValue={value => {
                if (value === "Sim") {
                  setData({
                    ...data,
                    endOfTreatmentTheSame: value,
                    endOfTreatment: "Em Uso",
                  });
                } else {
                  setData({
                    ...data,
                    endOfTreatmentTheSame: value,
                    endOfTreatment: "",
                  });
                }
              }}
              required
            />
          </ItemRow>
          <ItemRow>
            <InputRadio
              name="concomitantMedicationsTheSame"
              label="Paciente faz uso de outros medicamentos? *"
              maxWidth="50%"
              value={data.concomitantMedicationsTheSame}
              changeValue={value => {
                if (value === "Sim") {
                  setData({
                    ...data,
                    concomitantMedicationsTheSame: value,
                  });
                  setOtherMedicines({
                    ...otherMedicines,
                    medicinesOne: true,
                  });
                } else {
                  setOtherMedicines({
                    ...otherMedicines,
                    medicinesOne: false,
                  });
                  setData({
                    ...data,
                    concomitantMedicationsTheSame: value,
                    concomitantMedicationsOne: "",
                    recommendationOne: "",
                    startDateOne: "",
                    endDateOne: "",
                    dailyDoseOne: "",
                  });
                }
              }}
              required
            />
          </ItemRow>
          {data.concomitantMedicationsTheSame === "Sim" &&
          otherMedicines?.medicinesOne === true ? (
            <>
              <ItemRow>
                <InputText
                  label="Medicamento *"
                  maxWidth="250px"
                  value={data.concomitantMedicationsOne}
                  changeValue={value =>
                    setData({
                      ...data,
                      concomitantMedicationsOne: value,
                    })
                  }
                  required
                />
                <InputText
                  label="Indicação *"
                  maxWidth="250px"
                  value={data.recommendationOne}
                  changeValue={value =>
                    setData({
                      ...data,
                      recommendationOne: value,
                    })
                  }
                  required
                />
                <InputDate
                  label="Data de início *"
                  maxWidth="230px"
                  value={
                    data.startDateOne
                      ? unFormatDate(data.startDateOne)
                      : data.startDateOne
                  }
                  changeValue={value =>
                    setData({
                      ...data,
                      startDateOne: formatDate(value),
                    })
                  }
                  required
                />
                {data.endDateOneTheSame === "Não" ? (
                  <InputDate
                    label="Data de término *"
                    maxWidth="230px"
                    value={
                      data.endDateOne
                        ? unFormatDate(data.endDateOne)
                        : data.endDateOne
                    }
                    changeValue={value =>
                      setData({
                        ...data,
                        endDateOne: formatDate(value),
                      })
                    }
                  />
                ) : null}
                <InputRadio
                  name="endDateOneTheSame"
                  label="Em Uso? *"
                  maxWidth="150px"
                  value={data.endDateOneTheSame}
                  changeValue={value => {
                    if (value === "Sim") {
                      setData({
                        ...data,
                        endDateOneTheSame: value,
                        endDateOne: "Em Uso",
                      });
                    } else {
                      setData({
                        ...data,
                        endDateOneTheSame: value,
                        endDateOne: "",
                      });
                    }
                  }}
                  required
                />
                <InputText
                  label="Dose diária *"
                  maxWidth="200px"
                  value={data.dailyDoseOne}
                  changeValue={value =>
                    setData({
                      ...data,
                      dailyDoseOne: value,
                    })
                  }
                  required
                />
              </ItemRow>
              <RowAddButton>
                {otherMedicines?.medicinesTwo !== true ? (
                  <button
                    type="button"
                    onClick={() =>
                      setOtherMedicines({
                        ...otherMedicines,
                        medicinesTwo: true,
                      })
                    }
                  >
                    Adicionar Medicamento
                  </button>
                ) : null}
              </RowAddButton>
              {otherMedicines?.medicinesTwo === true ? (
                <>
                  <ItemRow>
                    <InputText
                      label="Medicamento *"
                      maxWidth="250px"
                      value={data.concomitantMedicationsTwo}
                      changeValue={value =>
                        setData({
                          ...data,
                          concomitantMedicationsTwo: value,
                        })
                      }
                      required
                    />
                    <InputText
                      label="Indicação *"
                      maxWidth="250px"
                      value={data.recommendationTwo}
                      changeValue={value =>
                        setData({
                          ...data,
                          recommendationTwo: value,
                        })
                      }
                      required
                    />
                    <InputDate
                      label="Data de início *"
                      maxWidth="230px"
                      value={
                        data.startDateTwo
                          ? unFormatDate(data.startDateTwo)
                          : data.startDateTwo
                      }
                      changeValue={value =>
                        setData({
                          ...data,
                          startDateTwo: formatDate(value),
                        })
                      }
                      required
                    />
                    {data.endDateTwoTheSame === "Não" ? (
                      <InputDate
                        label="Data de término *"
                        maxWidth="230px"
                        value={
                          data.endDateTwo
                            ? unFormatDate(data.endDateTwo)
                            : data.endDateTwo
                        }
                        changeValue={value =>
                          setData({
                            ...data,
                            endDateTwo: formatDate(value),
                          })
                        }
                      />
                    ) : null}
                    <InputRadio
                      name="endDateTwoTheSame"
                      label="Em Uso? *"
                      maxWidth="150px"
                      value={data.endDateTwoTheSame}
                      changeValue={value => {
                        if (value === "Sim") {
                          setData({
                            ...data,
                            endDateTwoTheSame: value,
                            endDateTwo: "Em Uso",
                          });
                        } else {
                          setData({
                            ...data,
                            endDateTwoTheSame: value,
                            endDateTwo: "",
                          });
                        }
                      }}
                      required
                    />
                    <InputText
                      label="Dose diária *"
                      maxWidth="200px"
                      value={data.dailyDoseTwo}
                      changeValue={value =>
                        setData({
                          ...data,
                          dailyDoseTwo: value,
                        })
                      }
                      required
                    />
                  </ItemRow>
                  <RowAddButton>
                    {otherMedicines?.medicinesThree !== true ? (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesThree: true,
                            })
                          }
                        >
                          Adicionar Medicamento
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesTwo: false,
                            });
                            setData({
                              ...data,
                              concomitantMedicationsTwo: "",
                              recommendationTwo: "",
                              startDateTwo: "",
                              endDateTwo: "",
                              dailyDoseTwo: "",
                            });
                          }}
                        >
                          Remover Medicamento
                        </button>
                      </>
                    ) : null}
                  </RowAddButton>
                </>
              ) : null}
              {otherMedicines?.medicinesThree === true ? (
                <>
                  <ItemRow>
                    <InputText
                      label="Medicamento *"
                      maxWidth="250px"
                      value={data.concomitantMedicationsThree}
                      changeValue={value =>
                        setData({
                          ...data,
                          concomitantMedicationsThree: value,
                        })
                      }
                      required
                    />
                    <InputText
                      label="Indicação *"
                      maxWidth="250px"
                      value={data.recommendationThree}
                      changeValue={value =>
                        setData({
                          ...data,
                          recommendationThree: value,
                        })
                      }
                      required
                    />
                    <InputDate
                      label="Data de início *"
                      maxWidth="230px"
                      value={
                        data.startDateThree
                          ? unFormatDate(data.startDateThree)
                          : data.startDateThree
                      }
                      changeValue={value =>
                        setData({
                          ...data,
                          startDateThree: formatDate(value),
                        })
                      }
                      required
                    />
                    {data.endDateThreeTheSame === "Não" ? (
                      <InputDate
                        label="Data de término *"
                        maxWidth="230px"
                        value={
                          data.endDateThree
                            ? unFormatDate(data.endDateThree)
                            : data.endDateThree
                        }
                        changeValue={value =>
                          setData({
                            ...data,
                            endDateThree: formatDate(value),
                          })
                        }
                      />
                    ) : null}
                    <InputRadio
                      name="endDateThreeTheSame"
                      label="Em Uso? *"
                      maxWidth="150px"
                      value={data.endDateThreeTheSame}
                      changeValue={value => {
                        if (value === "Sim") {
                          setData({
                            ...data,
                            endDateThreeTheSame: value,
                            endDateThree: "Em Uso",
                          });
                        } else {
                          setData({
                            ...data,
                            endDateThreeTheSame: value,
                            endDateThree: "",
                          });
                        }
                      }}
                      required
                    />
                    <InputText
                      label="Dose diária *"
                      maxWidth="200px"
                      value={data.dailyDoseThree}
                      changeValue={value =>
                        setData({
                          ...data,
                          dailyDoseThree: value,
                        })
                      }
                      required
                    />
                  </ItemRow>
                  <RowAddButton>
                    {otherMedicines?.medicinesFour !== true ? (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesFour: true,
                            })
                          }
                        >
                          Adicionar Medicamento
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesThree: false,
                            });
                            setData({
                              ...data,
                              concomitantMedicationsThree: "",
                              recommendationThree: "",
                              startDateThree: "",
                              endDateThree: "",
                              dailyDoseThree: "",
                            });
                          }}
                        >
                          Remover Medicamento
                        </button>
                      </>
                    ) : null}
                  </RowAddButton>
                </>
              ) : null}
              {otherMedicines?.medicinesFour === true ? (
                <>
                  <ItemRow>
                    <InputText
                      label="Medicamento *"
                      maxWidth="250px"
                      value={data.concomitantMedicationsFour}
                      changeValue={value =>
                        setData({
                          ...data,
                          concomitantMedicationsFour: value,
                        })
                      }
                      required
                    />
                    <InputText
                      label="Indicação *"
                      maxWidth="250px"
                      value={data.recommendationFour}
                      changeValue={value =>
                        setData({
                          ...data,
                          recommendationFour: value,
                        })
                      }
                      required
                    />
                    <InputDate
                      label="Data de início *"
                      maxWidth="230px"
                      value={
                        data.startDateFour
                          ? unFormatDate(data.startDateFour)
                          : data.startDateFour
                      }
                      changeValue={value =>
                        setData({
                          ...data,
                          startDateFour: formatDate(value),
                        })
                      }
                      required
                    />
                    {data.endDateFourTheSame === "Não" ? (
                      <InputDate
                        label="Data de término *"
                        maxWidth="230px"
                        value={
                          data.endDateFour
                            ? unFormatDate(data.endDateFour)
                            : data.endDateFour
                        }
                        changeValue={value =>
                          setData({
                            ...data,
                            endDateFour: formatDate(value),
                          })
                        }
                      />
                    ) : null}
                    <InputRadio
                      name="endDateFourTheSame"
                      label="Em Uso? *"
                      maxWidth="150px"
                      value={data.endDateFourTheSame}
                      changeValue={value => {
                        if (value === "Sim") {
                          setData({
                            ...data,
                            endDateFourTheSame: value,
                            endDateFour: "Em Uso",
                          });
                        } else {
                          setData({
                            ...data,
                            endDateFourTheSame: value,
                            endDateFour: "",
                          });
                        }
                      }}
                      required
                    />
                    <InputText
                      label="Dose diária *"
                      maxWidth="200px"
                      value={data.dailyDoseFour}
                      changeValue={value =>
                        setData({
                          ...data,
                          dailyDoseFour: value,
                        })
                      }
                      required
                    />
                  </ItemRow>
                  <RowAddButton>
                    {otherMedicines?.medicinesFive !== true ? (
                      <>
                        <button
                          type="button"
                          onClick={() =>
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesFive: true,
                            })
                          }
                        >
                          Adicionar Medicamento
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setOtherMedicines({
                              ...otherMedicines,
                              medicinesFour: false,
                            });
                            setData({
                              ...data,
                              concomitantMedicationsFour: "",
                              recommendationFour: "",
                              startDateFour: "",
                              endDateFour: "",
                              dailyDoseFour: "",
                            });
                          }}
                        >
                          Remover Medicamento
                        </button>
                      </>
                    ) : null}
                  </RowAddButton>
                </>
              ) : null}
              {otherMedicines?.medicinesFive === true ? (
                <>
                  <ItemRow>
                    <InputText
                      label="Medicamento *"
                      maxWidth="250px"
                      value={data.concomitantMedicationsFive}
                      changeValue={value =>
                        setData({
                          ...data,
                          concomitantMedicationsFive: value,
                        })
                      }
                      required
                    />
                    <InputText
                      label="Indicação *"
                      maxWidth="250px"
                      value={data.recommendationFive}
                      changeValue={value =>
                        setData({
                          ...data,
                          recommendationFive: value,
                        })
                      }
                      required
                    />
                    <InputDate
                      label="Data de início *"
                      maxWidth="230px"
                      value={
                        data.startDateFive
                          ? unFormatDate(data.startDateFive)
                          : data.startDateFive
                      }
                      changeValue={value =>
                        setData({
                          ...data,
                          startDateFive: formatDate(value),
                        })
                      }
                      required
                    />
                    {data.endDateFiveTheSame === "Não" ? (
                      <InputDate
                        label="Data de término *"
                        maxWidth="230px"
                        value={
                          data.endDateFive
                            ? unFormatDate(data.endDateFive)
                            : data.endDateFive
                        }
                        changeValue={value =>
                          setData({
                            ...data,
                            endDateFive: formatDate(value),
                          })
                        }
                      />
                    ) : null}
                    <InputRadio
                      name="endDateFiveTheSame"
                      label="Em Uso? *"
                      maxWidth="150px"
                      value={data.endDateFiveTheSame}
                      changeValue={value => {
                        if (value === "Sim") {
                          setData({
                            ...data,
                            endDateFiveTheSame: value,
                            endDateFive: "Em Uso",
                          });
                        } else {
                          setData({
                            ...data,
                            endDateFiveTheSame: value,
                            endDateFive: "",
                          });
                        }
                      }}
                      required
                    />
                    <InputText
                      label="Dose diária *"
                      maxWidth="200px"
                      value={data.dailyDoseFive}
                      changeValue={value =>
                        setData({
                          ...data,
                          dailyDoseFive: value,
                        })
                      }
                      required
                    />
                  </ItemRow>
                  <RowAddButton>
                    <button
                      type="button"
                      onClick={() => {
                        setOtherMedicines({
                          ...otherMedicines,
                          medicinesFive: false,
                        });
                        setData({
                          ...data,
                          concomitantMedicationsFive: "",
                          recommendationFive: "",
                          startDateFive: "",
                          endDateFive: "",
                          dailyDoseFive: "",
                        });
                      }}
                    >
                      Remover Medicamento
                    </button>
                  </RowAddButton>
                </>
              ) : null}
            </>
          ) : null}
          <ItemRow>
            <InputRadio
              name="concomitantDiseasesTheSame"
              label="Paciente possui alguma doença? *"
              maxWidth="50%"
              value={data.concomitantDiseasesTheSame}
              changeValue={value =>
                setData({
                  ...data,
                  concomitantDiseasesTheSame: value,
                })
              }
              required
            />
          </ItemRow>
          {data.concomitantDiseasesTheSame === "Sim" ? (
            <ItemRow>
              <InputText
                label="Doenças pré-existentes ou concomitantes (Ex.: hipertensão, diabetes, etc.): *"
                maxWidth="50%"
                value={data.concomitantDiseases}
                changeValue={value =>
                  setData({
                    ...data,
                    concomitantDiseases: value,
                  })
                }
                required
              />
            </ItemRow>
          ) : null}
          <br />
          <Title>Reação adversa</Title>
          <br />
          <ItemRow>
            <InputDate
              label="Início reação adversa *"
              maxWidth="250px"
              value={
                data.startReaction
                  ? unFormatDate(data.startReaction)
                  : data.startReaction
              }
              changeValue={value =>
                setData({
                  ...data,
                  startReaction: formatDate(value),
                })
              }
              required
            />
            {data.endReactionTheSame === "Não" ? (
              <InputDate
                label="Término reação adversa *"
                maxWidth="250px"
                value={
                  data.endReaction
                    ? unFormatDate(data.endReaction)
                    : data.endReaction
                }
                changeValue={value =>
                  setData({
                    ...data,
                    endReaction: formatDate(value),
                  })
                }
                required
              />
            ) : null}
            <InputRadio
              name="endReactionTheSame"
              label="Em resolução *"
              maxWidth="150px"
              value={data.endReactionTheSame}
              changeValue={value => {
                if (value === "Sim") {
                  setData({
                    ...data,
                    endReactionTheSame: value,
                    endReaction: "Em resolução",
                  });
                } else {
                  setData({
                    ...data,
                    endReactionTheSame: value,
                    endReaction: "",
                  });
                }
              }}
              required
            />
          </ItemRow>
          <ItemRow>
            <InputTextArea
              label="Qual(is) foi(ram) a(s) reação(ões) adversa(s) encontrada(s)? Descreva detalhadamente todas as reações *"
              value={data.adverseReactions}
              changeValue={value =>
                setData({
                  ...data,
                  adverseReactions: value,
                })
              }
              required
            />
          </ItemRow>
          <ItemRow>
            <InputRadio
              name="recoveredTheSame"
              label="Você se recuperou da reação? *"
              maxWidth="250px"
              value={data.recoveredTheSame}
              changeValue={value =>
                setData({
                  ...data,
                  recoveredTheSame: value,
                })
              }
              required
            />
            <InputRadio
              name="suspended"
              label="Suspendeu o uso do medicamento por causa da reação? *"
              value={data.suspended}
              maxWidth="450px"
              changeValue={value =>
                setData({
                  ...data,
                  suspended: value,
                })
              }
              required
            />
            <InputRadio
              name="adverseReaction"
              label="A reação adversa foi tratada? *"
              value={data.adverseReactionsTheSame}
              maxWidth="450px"
              changeValue={value =>
                setData({
                  ...data,
                  adverseReactionsTheSame: value,
                })
              }
              required
            />
          </ItemRow>
          {data.adverseReactionsTheSame === "Sim" ? (
            <ItemRow>
              <InputText
                label="Qual o tratamento? *"
                value={data.recovered}
                changeValue={value =>
                  setData({
                    ...data,
                    recovered: value,
                  })
                }
                required
              />
            </ItemRow>
          ) : null}
          <br />
          <Title>Outras informações</Title>
          <br />
          <ItemRow>
            <InputName
              label="Nome do profissional prescritor"
              maxWidth="350px"
              value={data.doctorName}
              changeValue={value =>
                setData({
                  ...data,
                  doctorName: value,
                })
              }
            />
            <InputText
              label="Cód. Conselho"
              maxWidth="200px"
              value={data.councilCode}
              changeValue={value =>
                setData({
                  ...data,
                  councilCode: value,
                })
              }
            />
            <InputCelAndPhone
              label="Fone do profissional prescritor"
              maxWidth="250px"
              value={data.doctorPhone}
              changeValue={value =>
                setData({
                  ...data,
                  doctorPhone: value,
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputText
              label="Onde este medicamento foi adquirido? (Ex.: farmácia, internet, amostra grátis, etc.)"
              maxWidth="50%"
              value={data.acquiredWhere}
              changeValue={value =>
                setData({
                  ...data,
                  acquiredWhere: value,
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputRadio
              label="Em caso de compra, possui nota fiscal?"
              maxWidth="50%"
              value={data.fiscalNote}
              changeValue={value =>
                setData({
                  ...data,
                  fiscalNote: value,
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <InputRadio
              name="canContact"
              label="Caso seja necessário, podemos entrar em contato para tratar exclusivamente sobre assuntos do evento adverso apontado? *"
              value={data.canContact}
              changeValue={value =>
                setData({
                  ...data,
                  canContact: value,
                })
              }
              required
            />
          </ItemRow>
          <ItemRow>
            <InputTextArea
              label="Observações:"
              value={data.observation}
              changeValue={value =>
                setData({
                  ...data,
                  observation: value,
                })
              }
            />
          </ItemRow>
          <ItemRow>
            <MessageCheck>
              Ao clicar no botão ENVIAR RELATO, entendo que os dados submetidos
              poderão ser compartilhados com órgãos reguladores e parceiros TRB
              Pharma envolvidos na cadeia produtiva e/ou no manejo de eventos
              adversos. Estou ciente de que os dados informados ficarão
              armazenados em meio sistemático e seguro sob responsabilidade do
              Detentor de Registro de Medicamento de acordo com a RDC 406/20.
            </MessageCheck>
          </ItemRow>
          <ItemRow>
            <Box onClick={() => setSelected(!selected)}>
              {selected === true ? <Checked /> : null}
            </Box>
            <CheckText>Eu li e concordo com os termos a cima</CheckText>
          </ItemRow>
          <ItemRow>
            <Button
              type={selected === true ? "submit" : "button"}
              isEnabled={selected}
            >
              Enviar Relato
            </Button>
          </ItemRow>
        </InputsDiv>
      </Container>
    </>
  );
};

export default PharmacovigilanceRender;
