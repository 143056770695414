import React, { useState, useEffect } from "react";

import { Button, Col, Row } from "react-bootstrap";
import { formatAmount } from "helpers/formats";
import type { LocatestoreResponse } from "./Locatestore/Locatestore.d";
import { Catalog } from "../Catalog.styles";

const notPrice = [
  "789628110055-2",
  "789628110059-0",
  "789628110058-3",
  "4028694000324",
  "4028694000102",
];

const Retailer = ({ data }: LocatestoreResponse) => {
  const [ean, setEan] = useState<string>("");
  const [offers, setOffers] = useState<
    LocatestoreResponse["data"][number]["offers"] | undefined
  >();

  useEffect(() => {
    if (data.length) {
      if (data.length < 2) {
        setOffers(data[0].offers);
      }

      setEan(data[0].ean);
    }
  }, [data]);

  if (!data.length) {
    return (
      <p style={{ textAlign: "center", padding: "3em" }}>
        Entre em contato com nosso SAC 0800 1005588
      </p>
    );
  }

  const handleSelect = (event: any) => {
    const id = event.target.value;
    const product = data.find(product => {
      return product.id.toString() === id.toString();
    });

    setOffers(product?.offers);
    setEan(product?.ean || "");
  };

  return (
    <>
      {data.length > 1 ? (
        <Catalog.BoxSelect>
          {
            <select className="form-select" onChange={handleSelect}>
              <option value={0} selected>
                Selecione a Apresentação
              </option>

              {data.map((item, index) => (
                <option value={item.id} key={`${item.id}-${index}`}>
                  {item.name}
                </option>
              ))}
            </select>
          }
        </Catalog.BoxSelect>
      ) : (
        <Catalog.TitleModal>{data[0].name}</Catalog.TitleModal>
      )}

      <Catalog.Retailer>
        {offers?.map((item, index) => {
          const {
            available,
            productTitleInRetailer,
            retailerLogo,
            retailer,
            price,
            fidelityPrice,
            url,
          } = item;

          return (
            <Row key={`${productTitleInRetailer}-${index}`} hidden={retailer !== 'Panvel' || fidelityPrice !== 0 ? false : true}>
              <Col className="image">
                <img src={retailerLogo} alt={productTitleInRetailer} />
              </Col>
              {notPrice.includes(ean) ? (
                <></>
              ) : (
                <Col className="price">{data[0].ean !== "789628115201-8" ? formatAmount(price) : retailer === "Panvel" ? formatAmount(fidelityPrice!) : formatAmount(price)}</Col>
              )}

              <Col className="status">{available ? "Disponível" : ""}</Col>
              <Col className="btn">
                <Button href={url}>
                  Comprar <span>&#8594;</span>
                </Button>
              </Col>
            </Row>
          );
        })}
      </Catalog.Retailer>
    </>
  );
};

export default Retailer;
